<template>
    <div>
        <Header></Header>
        <div class="w-full h-52 bg-[url('@/assets/bg-website.jpg')] bg-no-repeat bg-cover bg-center flex justify-center items-center align-middle">
            <h2 class="text-white">ตรวจสอบสถานะ</h2>
        </div>
            <div class="container mx-auto mt-10 mb-10">
                <h3 class="mt-5 font-normal"></h3>
                <h3 class="mt-5 font-normal ">รายละเอียดสำหรับการชำระเงินเข้าร่วมประชุมวิชาการ</h3>
                <div>
                    <h5 class="my-3">ข้อมูลผู้ลงทะเบียน</h5>
                    <div class="grid grid-cols-1 gap-2 mt-3">
                        <div class="border border-gray-400 col-span-2 rounded-sm ">
                            <div class=" w-full bg-blue-300 grid grid-cols-3 p-5">
                                <span> ชื่อ นามสกุล </span>
                                <span class="col-span-2"> {{ user.u_firstname }} {{ user.u_lastname }}   </span>
                            </div>
                            <div class=" w-full grid grid-cols-3 p-5">
                                <span> สถานะ </span>
                                <span class="col-span-2 text-green-500" v-if="user.u_status == 'true'"> ชำระแล้ว </span>
                                <span class="col-span-2 text-orange-500" v-if="user.u_status == 'pending'"> รอการตรวจสอบ </span>
                                <span class="col-span-2 text-red-500" v-if="user.u_status == 'false'"> ยังไม่ได้ชำระ </span>
                            </div>
                            <div class=" w-full bg-blue-300 grid grid-cols-3 p-5">
                                <span> ประเภทการลงทะเบียน </span>
                                <span class="col-span-2 capitalize"> {{ user.u_type }} </span>
                            </div>
                            <div class=" w-full grid grid-cols-3 p-5">
                                <span> จำนวนเงินที่ต้องชำระ	 </span>
                                <span class="col-span-2" v-if="user.u_type == 'onsite'">5,500 บาท </span>
                                <span class="col-span-2" v-if="user.u_type == 'online'">3,500 บาท </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="user.u_status == 'false'" class="border-slate-400 border p-3 mt-5 bg-slate-100">
                    <h5>อัตราการลงทะเบียน</h5>
                    <p>สำหรับเภสัชกร บุคลากรทางการแพทย์และคณาจารย์ คณะเภสัชศาสตร์</p>
                    <div class="w-full">
                        <div class="grid grid-cols-1 file:gap-2 my-3">
                            <div class="border border-gray-400 col-span-2 rounded-sm ">
                                <div class="w-full grid grid-cols-4 p-5">
                                    <span class="col-span-2 w-full">  </span>
                                    <span class="text-center"> Onsite <br> ณ โรงแรมเซ็นทารา ริเวอร์ไซด์ เชียงใหม่ </span>
                                    <span class="text-center"> Online  </span>
                                </div>
                                <div class=" w-full bg-slate-300 grid grid-cols-4 p-5">
                                    <span class="col-span-2 w-full"> ภายในวันที่ 4 มิถุนายน 2566 </span>
                                    <span class="text-center"> 5,500 บาท  </span>
                                    <span class="text-center"> 3,500 บาท  </span>
                                </div>
                                <div class=" w-full grid grid-cols-4 p-5">
                                    <span class="col-span-2 w-full"> หลังวันที่ 4 มิถุนายน 2566 </span>
                                    <span class="text-center"> 6,000 บาท  </span>
                                    <span class="text-center"> 4,000 บาท  </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <h4 class="font-semibold text-gray-500 my-5">ผู้เข้าร่วมประชุมสามารถชำระเงิน ตามรายละเอียดดังนี้</h4>
                    <img src="@/assets/logo-krungthai.png" class="w-48 h-auto mb-5" alt="scb logo">
                    <div class="grid grid-cols-1 gap-2 mt-3">
                        <div class="border border-gray-400 col-span-2 rounded-sm ">
                            <div class=" w-full bg-slate-300 grid grid-cols-3 p-5">
                                <span> ธนาคาร </span>
                                <span class="col-span-2"> ธนาคารกรุงไทย สาขาถนนสุเทพ  </span>
                            </div>
                            <div class=" w-full grid grid-cols-3 p-5">
                                <span> ประเภท </span>
                                <span class="col-span-2"> บัญชีออมทรัพย์ </span>
                            </div>
                            <div class=" w-full bg-slate-300 grid grid-cols-3 p-5">
                                <span> เลขที่บัญชี	 </span>
                                <span class="col-span-2"> 521-0-64016-7 </span>
                            </div>
                            <div class=" w-full grid grid-cols-3 p-5">
                                <span> ชื่อบัญชี </span>
                                <span class="col-span-2"> ประชุมวิชาการโรคหัวใจและหลอดเลือด </span>
                            </div>
                        </div>
                    </div>

                    <span class="text-red-500">*** ในกรณีที่มีผู้ลงทะเบียนเกินกว่าจำนวนที่รองรับได้ ทางผู้จัดงานขอสงวนสิทธิ์การเข้าร่วมประชุมให้แก่ผู้ที่ชำระค่าลงทะเบียนก่อน ***</span>

                    <div class="flex justify-center align-middle items-center my-7">
                        <a href="/paymentnotify"><button class="border rounded-md p-4 bg-blue-500 hover:border-blue-400 hover:bg-white text-white hover:text-black">แจ้งหลักฐานการชำระเงิน</button></a>
                    </div>
                </div>
            </div>
        <Footer></Footer>
    </div>
</template>

<script>
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import axios from 'axios';

export default {
    name: 'PaymentInfo',
    components: {
        Header,
        Footer
    },
    data() {
        return {
            user: {},
        }
    },
    mounted(){
        this.onHandleLogin();
        
        axios.post(`/api/payment/checkpayment`)
            .then(res => {
                if ( res.data ) {
                    this.user = res.data.user;
                }
            })
    },
    methods: {
        onHandleLogin(){
            axios.get(`/api/login`)
            .then(res => {
                if ( res.data.status !== true ) {
                    this.$router.push({ name: 'Home' })
                } 
            })
        },
    }
}
</script>

<style scoped>
    span{
        @apply text-lg
    }
</style>