<template>
  <div>
    <!-- Schedule start -->
        <div class="mb-5 border-b bg-white border-gray-200">
            <!-- <div class="sm:hidden">
                <label for="tabs" class="sr-only">Select your country</label>
                <select id="tabs" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                    <option>
                        <button class="inline-block w-full p-4 text-black bg-white hover:text-white hover:bg-black focus:text-white focus:bg-black" id="firstday-tab" data-tabs-target="#firstday" type="button" role="tab" aria-controls="firstday" aria-selected="false">
                            <span class="text-lg">DAY 1</span>
                            <p class="text-base font-['Kanit'] font-light">12 มิ.ย. 2566</p>
                        </button>
                    </option>
                    <option>
                        <button class="inline-block w-full p-4 text-black bg-white hover:text-white hover:bg-black focus:text-white focus:bg-black" id="secondday-tab" data-tabs-target="#secondday" type="button" role="tab" aria-controls="secondday" aria-selected="false">
                            <span class="text-lg">DAY 2</span>
                            <p class="text-base font-['Kanit'] font-light">13 มิ.ย. 2566</p>
                        </button>
                    </option>
                    <option>
                        <button class="inline-block w-full p-4 text-black bg-white hover:text-white hover:bg-black focus:text-white focus:bg-black" id="thirdday-tab" data-tabs-target="#thirdday" type="button" role="tab" aria-controls="thirdday" aria-selected="false">
                            <span class="text-lg">DAY 3</span>
                            <p class="text-base font-['Kanit'] font-light">14 มิ.ย. 2566</p>
                        </button>
                    </option>
                    <option>
                        <button class="inline-block w-full p-4 text-black bg-white hover:text-white hover:bg-black focus:text-white focus:bg-black" id="fourday-tab" data-tabs-target="#fourday" type="button" role="tab" aria-controls="fourday" aria-selected="false">
                            <span class="text-lg">DAY 4</span>
                            <p class="text-base font-['Kanit'] font-light">15 มิ.ย. 2566</p>
                        </button>
                    </option>
                    <option>
                        <button class="inline-block w-full p-4 text-black bg-white hover:text-white hover:bg-black focus:text-white focus:bg-black" id="fiveday-tab" data-tabs-target="#fiveday" type="button" role="tab" aria-controls="fiveday" aria-selected="false">
                        <span class="text-lg">DAY 4</span>
                        <p class="text-base font-['Kanit'] font-light">16 มิ.ย. 2566</p>
                    </button>
                    </option>
                </select>
            </div> -->
            <ul class="grid grid-cols-1 text-sm font-medium text-center sm:flex shadow-lg" id="myTab" data-tabs-toggle="#home-sc-tab" role="tablist">
                <li class="w-full" role="presentation">
                    <button class="inline-block w-full p-4 text-black bg-white hover:text-white hover:bg-black focus:text-white focus:bg-black" id="firstday-tab" data-tabs-target="#firstday" type="button" role="tab" aria-controls="firstday" aria-selected="false">
                        <span class="text-lg">DAY 1</span>
                        <p class="text-base font-['Kanit'] font-light">12 มิ.ย. 2566</p>
                    </button>
                </li>
                <li class="w-full" role="presentation">
                    <button class="inline-block w-full p-4 text-black bg-white hover:text-white hover:bg-black focus:text-white focus:bg-black" id="secondday-tab" data-tabs-target="#secondday" type="button" role="tab" aria-controls="secondday" aria-selected="false">
                        <span class="text-lg">DAY 2</span>
                        <p class="text-base font-['Kanit'] font-light">13 มิ.ย. 2566</p>
                    </button>
                </li>
                <li class="w-full" role="presentation">
                    <button class="inline-block w-full p-4 text-black bg-white hover:text-white hover:bg-black focus:text-white focus:bg-black" id="thirdday-tab" data-tabs-target="#thirdday" type="button" role="tab" aria-controls="thirdday" aria-selected="false">
                        <span class="text-lg">DAY 3</span>
                        <p class="text-base font-['Kanit'] font-light">14 มิ.ย. 2566</p>
                    </button>
                </li>
                <li class="w-full" role="presentation">
                    <button class="inline-block w-full p-4 text-black bg-white hover:text-white hover:bg-black focus:text-white focus:bg-black" id="fourday-tab" data-tabs-target="#fourday" type="button" role="tab" aria-controls="fourday" aria-selected="false">
                        <span class="text-lg">DAY 4</span>
                        <p class="text-base font-['Kanit'] font-light">15 มิ.ย. 2566</p>
                    </button>
                </li>
                <li class="w-full" role="presentation">
                    <button class="inline-block w-full p-4 text-black bg-white hover:text-white hover:bg-black focus:text-white focus:bg-black" id="fiveday-tab" data-tabs-target="#fiveday" type="button" role="tab" aria-controls="fiveday" aria-selected="false">
                        <span class="text-lg">DAY 5</span>
                        <p class="text-base font-['Kanit'] font-light">16 มิ.ย. 2566</p>
                    </button>
                </li>
            </ul>
        </div>
        <div id="home-sc-tab" class="mb-5">
            <div class="hidden p-4 rounded-md bg-white space-y-4" id="firstday" role="tabpanel" aria-labelledby="firstday-tab">      
                <img src="@/assets/agenda/AGENDA_1.png" alt="agenda">              
                <!-- <div class="w-full h-36 bg-[#D0E0E2] rounded-lg" v-for="(item, index) in scheduleList" :key="index">
                    <div class="grid grid-cols-2">
                        <div class="grid grid-cols-3 py-4">
                            <div class="flex justify-center align-middle items-center">
                                <img class="w-28 h-28 ml-10" v-if="item.sc_image" :src="item.sc_image" alt="profile">
                                <div class="w-28 h-28 ml-10" v-if="!item.sc_image"></div>
                            </div>
                            <div class="flex items-start justify-center align-middle flex-col col-span-2 ml-5">
                                <p v-if="item.sc_speaker" class="text-[#021734] font-medium"> {{ item.sc_speaker }} </p>
                                <p v-if="!item.sc_speaker" class="text-[#021734] font-medium"> {{ item.sc_title }} </p>
                                <p class="text-[#021734]"> {{ onDisplayDate(item.sc_date) }} </p>
                                <p class="text-[#021734]"> {{ item.sc_time_from }} - {{ item.sc_time_to }} น.</p>
                            </div>
                        </div>
                        <div class="flex flex-col justify-center">
                            <div class="flex justify-center items-center">
                                <p class="text-lg font-medium text-[#021734]" v-if="item.sc_speaker"> {{ item.sc_title }} </p>
                            </div>
                            <div class="flex justify-end mr-5" v-if="item.sc_document">
                                <a target="_blank" class="text-[#021734] border border-[#021734] rounded-lg px-5 hover:bg-[#021734] hover:text-white" :href="`/pdfview/${item._id}`">เอกสารประกอบ</a>
                            </div>
                        </div>
                    </div>
                </div> -->
            </div>
            <div class="hidden p-4 rounded-lg bg-gray-50 " id="secondday" role="tabpanel" aria-labelledby="secondday-tab">
                <img src="@/assets/agenda/AGENDA_2.png" alt="agenda">
            </div>
            <div class="hidden p-4 rounded-lg bg-gray-50 " id="thirdday" role="tabpanel" aria-labelledby="thirdday-tab">
                <img src="@/assets/agenda/AGENDA_3.png" alt="agenda">
            </div>
            <div class="hidden p-4 rounded-lg bg-gray-50 " id="fourday" role="tabpanel" aria-labelledby="fourday-tab">
                <img src="@/assets/agenda/AGENDA_4.png" alt="agenda">
            </div>
            <div class="hidden p-4 rounded-lg bg-gray-50 " id="fiveday" role="tabpanel" aria-labelledby="fiveday-tab">
                <img src="@/assets/agenda/AGENDA_5.png" alt="agenda">
            </div>
        </div>
        <!-- schedule end -->
  </div>
</template>

<script setup>
import { onMounted } from 'vue'
import { initTabs } from 'flowbite'

onMounted(() => {
    initTabs();
})
</script>


<script>
import axios from 'axios';
import moment from 'moment'

export default {
    name: 'Schedule',
    data() {
        return {
            scheduleList: ''
        }
    },
    mounted() {
        this.onGetData();
    },
    methods: {
        onGetData() {
            axios.get(`/api/schedule`)
                .then(response => {
                    this.scheduleList = response.data;
                })
        },
        onDisplayDate(value){
            return moment(value).format('DD/MM/YYYY')
        },
    }
}
</script>

<style>

</style>