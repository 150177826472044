<template>
    <section class="bg-[url('@/assets/bg-website.jpg')] bg-no-repeat bg-cover bg-center">
      <div class="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
          <div class="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
              <div class="p-6 space-y-4 md:space-y-6 sm:p-8">
                  <h1 class="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                        Administrator
                  </h1>
                  <form class="space-y-4 md:space-y-6" @submit.prevent="onSubmit">
                      <div>
                          <label for="email" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Username</label>
                          <input v-model="form.u_email" type="text" class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-[#2563eb] focus:border-primary-[#2563eb] block w-full p-2.5" required>
                      </div>
                      <div>
                          <label for="password" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Password</label>
                          <input v-model="form.u_password" type="password" name="password" id="password" placeholder="••••••••" class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-[#2563eb] focus:border-primary-[#2563eb] block w-full p-2.5" required>
                      </div>
                      <button type="submit" class="w-full text-white bg-[#2563eb] rounded-lg px-5 py-2.5">Sign in</button>
                  </form>
              </div>
          </div>
      </div>
      </section>
  </template>
  
  <script>
  import axios from 'axios';
  import Swal from 'sweetalert2';
  import { store } from '@/store';

  export default {
      name: 'AdminLogin',
      data() {
          return {
              form: {
                  u_email: '',
                  u_password: ''
              },
              store
          }
      },
      methods: {
          onSubmit(){
              axios.post(`/api/login`, this.form)
                .then(res => {
                    if ( res.data.status === true ) {
                        this.$router.push({ name: 'AdminHome' });
                    } else {
                        Swal.fire(
                            'Username or Password invlid?',
                            'That thing is still around?',
                            'error'
                        )
                    }
                })
          }
      }
  }
  </script>
  
  <style scoped>
  
  </style>