<template>
    <div>
        <Header></Header>
            <div class="container my-12">
                <h3 class="font-medium mb-2">ข้อมูลส่วนตัว</h3>
                <p>สถานะการชำระเงิน 
                    <span v-if="form.u_status == 'false'" class="bg-red-100 text-red-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded">ยังไม่ได้ชำระเงิน</span>
                    <span v-if="form.u_status == 'pending'" class="bg-yellow-100 text-yellow-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded">รอตรวจสอบหลักฐานการชำระเงิน</span>
                    <span v-if="form.u_status == 'true'" class="bg-green-100 text-green-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded">ชำระเงินแล้ว</span>
                </p>
                <div class="mt-5 space-y-3">
                    <div class="grid grid-cols-2 gap-2">
                        <div>
                            <label class="block mb-2 text-sm font-medium text-gray-900">ชื่อ - นามสกุล</label>
                            <input type="text" :value="form.u_firstname + ' ' + form.u_lastname" class="bg-gray-50 border border-gray-300 text-black text-sm rounded-lgblock w-full p-2.5" readonly>
                        </div>
                        <div>
                            <label class="block mb-2 text-sm font-medium text-gray-900">ประเภทการลงทะเบียน</label>
                            <input type="text" :value="form.u_type" class="uppercase bg-gray-50 border border-gray-300 text-black text-sm rounded-lgblock w-full p-2.5" readonly>
                        </div>
                    </div>
                    <div class="grid grid-cols-2 gap-2">
                        <div>
                            <label class="block mb-2 text-sm font-medium text-gray-900">อีเมล์</label>
                            <input type="text" :value="form.u_email" class="bg-gray-50 border border-gray-300 text-black text-sm rounded-lgblock w-full p-2.5" readonly>
                        </div>
                        <div>
                            <label class="block mb-2 text-sm font-medium text-gray-900">เบอร์โทรศัพท์</label>
                            <input type="text" :value="form.u_phone" class="bg-gray-50 border border-gray-300 text-black text-sm rounded-lgblock w-full p-2.5" readonly>
                        </div>
                    </div>
                </div>
            </div>
    </div>
</template>

<script>
import Header from '@/components/Header.vue';
import axios from 'axios';

export default {
    name: 'profile',
    components: {
        Header
    },
    data() {
        return {
            form: ''
        }
    },
    mounted() {
        axios.get('/api/login')
            .then(res => {
                if (res.data.status === true) {
                    this.form = res.data.docs;
                } else {
                    this.$router.push({ name : 'Home' })
                }
            })
    },
    methods: {
        
    }
}
</script>

<style scoped>
    
</style>