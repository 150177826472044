<template>
    <div class="bg-slate-300 min-h-screen">
        <AdminHeader></AdminHeader>
        <div class="container mx-auto mt-10">
            <div class="flex justify-end items-center mb-3">
                <button type="button" data-modal-target="speaker-modal" data-modal-toggle="speaker-modal" class="px-5 bg-blue-700 rounded-sm py-2 text-white hover:bg-yellow-400 hover:text-black">เพิ่มข้อมูล</button>
            </div>

            <!-- MODEL -->
            <!-- Main modal -->
            <form @submit.prevent="onInsert" ref="speakerModal" id="speaker-modal" tabindex="-1" aria-hidden="true" class="fixed top-0 left-0 right-0 z-50 hidden w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] md:h-full">
                <div class="relative w-full h-full max-w-md md:h-auto">
                    <!-- Modal content -->
                    <div class="relative bg-white rounded-lg shadow">
                        <button type="button" class="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white" data-modal-hide="speaker-modal">
                            <svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                            <span class="sr-only">Close modal</span>
                        </button>
                        <div class="px-6 py-6 lg:px-8">
                            <h3 class="mb-4 text-xl font-medium text-gray-900 ">เพิ่มข้อมูลวิทยากร</h3>
                            <div class="space-y-4">
                                <div>
                                    <label for="firstname" class="block mb-2 text-sm font-medium text-gray-900 ">ชื่อจริง</label>
                                    <input required v-model="form.s_firstname" type="text" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
                                </div>
                                <div>
                                    <label for="lastname" class="block mb-2 text-sm font-medium text-gray-900 ">นามสกุล</label>
                                    <input required v-model="form.s_lastname" type="text" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
                                </div>
                                <div>
                                    <label for="academic" class="block mb-2 text-sm font-medium text-gray-900 ">ตำแหน่ง</label>
                                    <input required v-model="form.s_academic" type="text" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
                                </div>
                                <div>
                                    <label for="agency" class="block mb-2 text-sm font-medium text-gray-900 ">สังกัด / หน่วยงาน</label>
                                    <input required v-model="form.s_agency" type="text" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
                                </div>
                                <div>
                                    <label for="image" class="block mb-2 text-sm font-medium text-gray-900 ">รูปภาพ</label>
                                    <input @change="onHandleImage($event)" type="file" />
                                </div>
                                <button type="submit" class="w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">บันทึก</button>
                            </div>
                        </div>
                    </div>
                </div>
            </form> 

            <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
                <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                    <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                        <tr>
                            <th scope="col" class="px-6 py-3">
                                ชื่อ - นามสกุล
                            </th>
                            <th scope="col" class="px-6 py-3">
                                ตำแหน่ง
                            </th>
                            <th scope="col" class="px-6 py-3">
                                สังกัด / หน่วยงาน
                            </th>
                            <th scope="col" class="px-6 py-3">
                                รูปภาพ
                            </th>
                            <th scope="col" class="px-6 py-3 text-center">
                                จัดการข้อมูล
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="bg-white border-b hover:bg-gray-200" v-for="(item, index) in speakerList" :key="index">
                            <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap ">
                                {{ item.s_firstname }} {{ item.s_lastname }}
                            </th>
                            <td class="px-6 py-4">
                                {{ item.s_academic }}
                            </td>
                            <td class="px-6 py-4">
                                {{ item.s_agency }}
                            </td>
                            <td class="px-6 py-4 text-center">
                                <img :src="item.s_image" alt="profile" class="w-16 h-16 bg-blue-600 rounded-full p-1">
                            </td>
                            <td class="px-6 py-4 text-center gap-1">
                                <button @click="this.$router.push({ path: `/management/speaker/edit/${item._id}` })" class="border bg-blue-700 py-1 px-2 rounded-md hover:bg-blue-400"><font-awesome-icon color="white" icon="fa-regular fa-pen-to-square" /></button>
                                <button @click="onDelete(item._id)" class="border bg-red-700 py-1 px-2 rounded-md hover:bg-red-400"><font-awesome-icon color="white" icon="fa-regular fa-trash-can" /></button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script setup>
import { onMounted } from 'vue'
import { initModals } from 'flowbite'

onMounted(() => {
    initModals();
})
</script>

<script>
import AdminHeader from '@/components/Admin/AdminHeader.vue';
import axios from 'axios';
import Swal from 'sweetalert2'

export default {
    name: 'SpeakerPage',
    components: {
        AdminHeader
    },
    data() {
        return {
            speakerList: '',
            form: {
                s_firstname: '',
                s_lastname: '',
                s_academic: '',
                s_agency: '',
                s_image: ''
            },
        }
    },
    mounted() {
        this.onGetData();

        axios.get(`/api/login`).then(res => {
            if (res.data.status === true) {
                if ( res.data.docs.u_role != 'admin' ) {
                    this.$router.push({ name: 'AdminLogin' })
                }
            } else {
                this.$router.push({ name: 'AdminLogin' })
            }
        });
    },
    methods: {
        onGetData() {
            axios.get(`/api/speaker`)
                .then(response => {
                    this.speakerList = response.data;
                })
        },
        onHandleImage(event){
            const file = event.target.files[0];
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                this.form.s_image = reader.result;
            };
                reader.onerror = (error) => {
                    console.log('Error: ', error);
            };            
        },
        onInsert(){
            axios.post(`/api/speaker`, this.form)
                .then(res => {
                    if ( res.status === 200 ) {
                        window.location.reload();
                    } 
                })
                .catch(error => console.log(error))
        },
        onDelete(id){
            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
                }).then((result) => {
                if (result.isConfirmed) {
                    axios.delete(`/api/speaker/${id}`).then(res => {
                        if ( res.status === 200 ) {
                            Swal.fire(
                                'Deleted!',
                                'Your file has been deleted.',
                                'success'
                            )

                            this.onGetData();
                        }
                    })
                }
            })
        }
    }
}
</script>

<style>
    
</style>