<template>
    <div>
        <AdminHeader></AdminHeader>

        <div class="container mx-auto mt-5">
            <div class="flex justify-end mb-3">   
                <input @keyup="onSearch($event.target.value)" placeholder="ค้นหาจากชื่อ" type="text" class="rounded-md">
            </div>
            <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
                <table class="w-full text-sm text-left text-gray-500">
                    <thead class="text-xs text-gray-700 uppercase bg-gray-50">
                        <tr>
                            <th scope="col" class="px-6 py-3 cursor-pointer" @click="onSortName()">
                                ชื่อผู้ลงทะเบียน
                            </th>
                            <th scope="col" class="px-6 py-3">
                                ชื่อผู้โอน
                            </th>
                            <th scope="col" class="px-6 py-3">
                                สถานะ
                            </th>
                            <th scope="col" class="px-6 py-3 text-center">
                                รายละเอียด
                            </th>
                        </tr>
                </thead>
                <tbody>
                        <tr class="bg-white border-b hover:bg-gray-200" v-for="(item, index) in PaymentPerpage" :key="index">
                            <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap ">
                                {{ item.user.u_firstname }} {{ item.user.u_lastname }}
                            </th>
                            <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap ">
                                {{ item.payment.p_transfer_name }}
                            </th>
                            <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap ">
                                <span v-if="item.payment.p_status === 'true'" class="text-white border-2 bg-green-500 px-3 rounded-lg"> ตรวจสอบแล้ว </span>
                                <span v-if="item.payment.p_status === 'false'" class="text-red-700"> รอตรวจสอบ </span>
                            </th>
                            <td class="px-6 py-4 text-center gap-1 space-x-2">
                                <button @click="onDetail(item.payment._id)" class="border bg-blue-700 py-1 px-2 rounded-md hover:bg-blue-400"><font-awesome-icon color="white" icon="fa-solid fa-list-check" /></button>
                                <button @click="onDelete(item.payment._id)" class="border bg-red-700 py-1 px-2 rounded-md hover:bg-red-400"><font-awesome-icon color="white" icon="fa-regular fa-trash-can" /></button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div class="flex justify-between items-center mb-8">
                <p class="ml-3">จำนวนทั้งหมด : {{ totalPayment }}</p>
                <nav class="mt-3">
                    <ul class="inline-flex -space-x-px" v-for="index in Math.ceil(totalPayment / perPage)" :key="index">
                        <li v-if="index === page">
                            <button @click="onChangePagination(index)" class="px-3 py-2 leading-tight text-white bg-blue-700 border border-gray-300 hover:bg-gray-100 hover:text-gray-700 rounded-md"> {{ index }} </button>
                        </li>
                        <li v-else>
                            <button @click="onChangePagination(index)" class="px-3 py-2 leading-tight text-white bg-gray-400 border border-gray-300 hover:bg-gray-100 hover:text-gray-700 rounded-md"> {{ index }} </button>
                        </li>
                    </ul>
                </nav>
            </div>

        </div>
    </div>
</template>

<script>
import AdminHeader from '@/components/Admin/AdminHeader.vue';
import axios from 'axios';
import Swal from 'sweetalert2';
export default {
    name: 'PaymentPage',
    components: {
        AdminHeader
    },
    data() {
        return {
            PaymentList: '',
            UserList: '',
            PaymentPerpage: '',
            totalPayment: 0,
            perPage: 20,
            page: 1
        }
    },
    mounted(){
        this.onGetPaymentList();

        axios.get(`/api/login`).then(res => {
            if (res.data.status === true) {
                if ( res.data.docs.u_role != 'admin' ) {
                    this.$router.push({ name: 'AdminLogin' })
                }
            } else {
                this.$router.push({ name: 'AdminLogin' })
            }
        });
    },
    methods: {
        onGetPaymentList(){
            axios.get(`/api/payment`)
                .then(res => {
                    this.totalPayment = res.data.length;
                    this.PaymentList = res.data;
                    this.PaymentPerpage = this.PaymentList.slice(0,20);
                })
            // axios.get(`/api/payment/getpayment/${this.page}`)
            //     .then(res => {
            //         this.PaymentList = res.data;
            //     })
        },
        onDetail(id){
            this.$router.push({ path: `/management/payment/${id}` })
        },
        onDelete(id){
            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
                }).then((result) => {
                if (result.isConfirmed) {
                    axios.delete(`/api/payment/${id}`).then(res => {
                        if ( res.status === 200 ) {
                            Swal.fire(
                                'Deleted!',
                                'Your file has been deleted.',
                                'success'
                            )

                            this.onGetPaymentList();
                        }
                    })
                }
            })
        },
        onSortName(){
            this.PaymentList.sort(function(a, b){
                if(a.user.u_firstname < b.user.u_firstname) { return -1; }
                if(a.user.u_firstname > b.user.u_firstname) { return 1; }
                return 0;
            })

            this.page = 1
            this.PaymentPerpage = this.PaymentList.slice(0, 20)
        },
        onChangePagination(page) {
            this.page = page;
            let item = ((page - 1) * 20) + 1
            this.PaymentPerpage = this.PaymentList.slice(item, (item + 20))
            // this.onGetPaymentList();
        },
        onSearch(text) {
            this.PaymentPerpage = this.PaymentList.filter((str) => { return str.user.u_firstname.includes(text) })
        }
    }
}
</script>

<style>
    
</style>