<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {
      
    }
  },
  components: {
    
  },
  mounted(){
    
  }
}
</script>

<style>
้ html, body {
  scroll-behavior: smooth;
}
</style>
