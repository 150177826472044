<template>
    <div>
        <AdminHeader></AdminHeader>
        <div class="container mx-auto">
            <div class="px-6 py-6 lg:px-8">
                <h3 class="mb-4 text-xl font-medium text-gray-900 ">แก้ไขข้อมูลวิทยากร</h3>
                    <form @submit.prevent="onUpdate" class="space-y-4">
                        <div>
                            <label for="firstname" class="block mb-2 text-sm font-medium text-gray-900 ">ชื่อจริง</label>
                                    <input required v-model="form.s_firstname" type="text" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
                                </div>
                                <div>
                                    <label for="lastname" class="block mb-2 text-sm font-medium text-gray-900 ">นามสกุล</label>
                                    <input required v-model="form.s_lastname" type="text" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
                                </div>
                                <div>
                                    <label for="academic" class="block mb-2 text-sm font-medium text-gray-900 ">ตำแหน่ง</label>
                                    <input required v-model="form.s_academic" type="text" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
                                </div>
                                <div>
                                    <label for="agency" class="block mb-2 text-sm font-medium text-gray-900 ">สังกัด / หน่วยงาน</label>
                                    <input required v-model="form.s_agency" type="text" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
                                </div>
                                <div>
                                    <img :src="this.form.s_image" alt="profile" class="w-24 h-24">
                                </div>
                                <div>
                                    <label for="image" class="block mb-2 text-sm font-medium text-gray-900 ">รูปภาพ</label>
                                    <input @change="onHandleImage($event)" type="file" />
                                </div>
                                <div class="flex justify-center">
                                    <button type="submit" class="w-1/2 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">บันทึก</button>
                                </div>
                            </form>
                        </div>
        </div> 
    </div>
</template>

<script>
import AdminHeader from '@/components/Admin/AdminHeader.vue';
import axios from 'axios';

export default {
    name: 'SpeakerEdit',
    components: {
        AdminHeader
    },
    data() {
        return {
            form: {
                s_firstname: '',
                s_lastname: '',
                s_academic: '',
                s_agency: '',
                s_image: ''
            },
        }
    },
    mounted(){
        this.onGetData()
    },
    methods: {
        onGetData(){
            axios.get(`/api/speaker/${this.$route.params.id}`)
                .then(res => {
                    this.form = res.data;
                })
        },
        onHandleImage(event){
            const file = event.target.files[0];
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                this.form.s_image = reader.result;
            };
                reader.onerror = (error) => {
                    console.log('Error: ', error);
            };            
        },
        onUpdate(){
            axios.put(`/api/speaker/${this.$route.params.id}`, this.form)
                .then(res => {
                    if (res.status === 200){
                        this.$router.push({name: 'SpeakerPage'})
                    }
                })
        }
    }
}
</script>

<style>
    
</style>