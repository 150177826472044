<template>
    <div>
        <Header></Header>
        <div class="container mx-auto mt-10">
            <div class="flex justify-center flex-col items-center">
                <h1 class="mb-5">หลักฐานการโอนเงินอยู่ระหว่างตรวจสอบ</h1>
                <img class="w-72 h-auto" src="@/assets/pending.png" alt="pending">
                <div class="mt-5 flex justify-center flex-col items-center">
                    <h5>ท่านได้ส่งหลักฐานการชำระเงินแล้ว</h5>
                    <span>หลักฐานการชำระเงินของท่านอยู่ระหว่างการตรวจสอบ</span>
                </div>
            </div>
        </div>
        <Footer></Footer>
    </div>
</template>

<script>
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import { store } from '@/store';

export default {
    name: 'PaymentWaiting',
    components: {
        Header,
        Footer
    },
    data() {
        return {
            store
        }
    },
    mounted(){

    },
    methods: {
        
    }
}
</script>

<style>
    
</style>