<template>
    <div class="bg-[#021734]">
        <div class="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0 space-y-4">
            <h1 class="text-white">ลงทะเบียน</h1>
            <div class="grid grid-cols-1 lg:grid-cols-2 gap-4">
                <div id="onsiteBlock" class="w-96 h-auto border border-gray-500 rounded-lg flex justify-center items-center flex-col space-y-2 py-5 cursor-pointer transition-all hover:bg-gray-500">
                    <span v-if="countOnsite >= 270" class="text-2xl font-bold text-orange-400">ผู้ลงทะเบียนครบตามจำนวนแล้ว</span>
                    <span class="text-2xl font-bold text-orange-400">ปิดการรับสมัคร</span>
                    <font-awesome-icon color="white" :icon="['fas', 'person-walking-dashed-line-arrow-right']" size="2xl" />
                    <h1 v-if="countOnsite < 270" class="text-transparent bg-clip-text bg-gradient-to-r from-purple-500 to-pink-500 tracking-wide text-[5rem] font-bold">Onsite</h1>
                    <h1 v-if="countOnsite >= 270" class="text-white tracking-wide text-[5rem] font-bold">Onsite</h1>
                    <p class="px-5 text-md text-white indent-4">การลงทะเบียน onsite ค่าลงทะเบียน 5,500 บาท </p>
                    <ul>
                        <li>สามารถเข้ารับฟังการบรรยาย</li>
                        <li>ณ งานประชุม อาหารเที่ยงและเบรค 2 มื้อ</li>
                        <li>หนังสืองานประชุม</li>
                        <li>ชมการบรรยายย้อนหลัง</li>
                        <li>CPE สำหรับเภสัชกร</li>
                    </ul>
                </div>
                <div class="w-96 h-auto border border-gray-500 rounded-lg flex justify-center items-center flex-col space-y-2 py-5 cursor-pointer transition-all hover:bg-gray-500">
                    <span class="text-2xl font-bold text-orange-400">ปิดการรับสมัคร</span>
                    <font-awesome-icon :icon="['fas', 'globe']" size="2xl" style="color: #ffffff;" />
                    <h1 class="text-transparent bg-clip-text bg-gradient-to-r from-purple-500 to-pink-500 tracking-wide text-[5rem] font-bold">Online</h1>
                    <p class="px-5 text-md text-white indent-4">การลงทะเบียน online ค่าลงทะเบียน 3,500 บาท</p>
                    <ul>
                        <li>สามารถเข้ารับฟังการบรรยาย</li>
                        <li>ผ่าน website งานประชุม </li>
                        <li>หนังสืองานประชุม</li>
                        <li>ชมการบรรยายย้อนหลัง</li>
                        <li>CPE สำหรับเภสัชกร</li>
                    </ul>
                </div>
            </div>
            <a href="/" class="text-white hover:text-blue-300 text-2xl">หน้าหลัก</a>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import Swal from 'sweetalert2';

export default {
    name: 'RegisterType',
    data() {
        return {
            countOnsite: 0
        }
    },
    mounted() {
        this.onCountOnsite();
    },
    methods: {
        onSelectType(type){
            if (type === 'online') {
                this.$router.push({ name: 'RegisterPage', params: { u_type: type }});
            } else {
                if (this.countOnsite < 270) {
                    this.$router.push({ name: 'RegisterPage', params: { u_type: type }});
                } else {
                    Swal.fire(
                    'ผู้ลงทะเบียนครบตามจำนวนแล้ว',
                    'กรุณาติดต่อเจ้าหน้าที่',
                    'question'
                    )
                }
            }
        },
        onCountOnsite() {
            axios.get(`/api/user/countonsite`)
            .then(res => {
                this.countOnsite = res.data;
            })
        },
    }
}
</script>

<style scoped>
    ul li {
        @apply text-white font-['Kanit']
    }
</style>