<template>
  
<footer class="p-4 bg-[#021734] shadow md:p-6">
    <div class="container mx-auto flex justify-between">
        <div>
            <div class="grid grid-cols-3 gap-0">
                <img class="w-20" src="@/assets/pharmacy_logo.png" alt="pharmacy logo">
                <div class="col-span-2">
                    <p class="text-white">คณะเภสัชศาสตร์ มหาวิทยาลัยเชียงใหม่</p>
                    <p class="text-white">Faculty of Pharmacy, Chiangmai University</p>
                </div>
            </div>
            <div class="mt-5 space-y-2">
                <span class="text-white">สอบถามรายละเอียดการประชุมเพิ่มเติมได้ที่ หน่วยบริการวิชาการ คณะเภสัชศาสตร์</span>
                <p class="text-white">โทร. 053-944374 / Email: cpe.pharmcmu@gmail.com / Line ID : @wal3469f</p>
            </div>
        </div>
        <div>
            <div>
                <p class="text-gray-300 text-sm">Copyright © 2023 Faculty of Pharmacy</p>
                <p class="text-gray-300 text-sm">All rights reserved. by Information Technology Service Center</p>
            </div>
            <a href="https://www.facebook.com/EPICCMU" class="text-white" target="_blank"><font-awesome-icon class="mt-5 hover:text-blue-300 mr-2" color="white" :icon="['fab', 'facebook']" size="2xl" /> Excellent Pharmacy Practice in Cardiovascular Diseases </a>
        </div>
    </div>
</footer>

</template>

<script>
export default {
    name: "Footer"
}
</script>

<style>

</style>