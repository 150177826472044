<template>
    <div>
        <AdminHeaderVue></AdminHeaderVue>

        <div class="container mt-5">
            <h3 class="text-center">ลิ้งค์ถ่ายทอดสด</h3>
            <div class="flex justify-center my-5 space-x-4">
                <input type="text" v-model="video_list.d_link" />
                <button @click="onSubmit" class="bg-green-400 hover:bg-green-600 text-white px-3 py-1 rounded-md">บันทึก</button>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import AdminHeaderVue from '@/components/Admin/AdminHeader.vue';
import Swal from 'sweetalert2';

export default {
    name: 'videomanage',
    components: {
        AdminHeaderVue
    },
    data() {
        return {
            video_list: '',
            d_link: ''
        }
    },
    mounted() {
        axios.get(`/api/login`).then(res => {
            if (res.data.status === true) {
                if ( res.data.docs.u_role != 'admin' ) {
                    this.$router.push({ name: 'AdminLogin' })
                }
            } else {
                this.$router.push({ name: 'AdminLogin' })
            }
        });

        this.onGetVideo()
    },
    methods: {
        onGetVideo() {
            axios.get('/api/document/video').then(res => this.video_list = res.data)
        },
        onSubmit() {
            axios.put(`/api/document/video/${this.video_list._id}`, this.video_list).then(() => {
                Swal.fire({
                            icon: 'success',
                            title: 'Your work has been saved',
                            showConfirmButton: false,
                            timer: 1500
                        })
            } )
        }
    }
}
</script>

<style>
    
</style>