<template>
    <section class="bg-[url('@/assets/bg-website.jpg')] bg-no-repeat bg-cover bg-center">
      <div class="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
          <h3 class="flex items-center mb-6 text-white">
              EPIC 2023
          </h3>
          <div class="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
              <div class="p-6 space-y-4 md:space-y-6 sm:p-8">
                  <h1 class="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                      Reset Password
                  </h1>
                  <form class="space-y-4 md:space-y-6" @submit.prevent="onSubmit">
                      <div>
                          <label for="email" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Password</label>
                          <input @keyup="onCheckPassword" v-model="form.password" type="password" name="password" id="password" class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-[#2563eb] focus:border-primary-[#2563eb] block w-full p-2.5" placeholder="name@company.com" required>
                      </div>
                      <div>
                          <label for="email" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Confirm Password</label>
                          <input @keyup="onCheckPassword" v-model="confirm_password" type="password" name="confirm_password" id="confirm_password" class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-[#2563eb] focus:border-primary-[#2563eb] block w-full p-2.5" placeholder="name@company.com" required>
                      </div>
                      <small v-if="CheckPassword" class="font-['Kanit'] text-red-600">รหัสผ่านไม่ตรงกัน</small>
                      <button type="submit" class="w-full text-white bg-[#2563eb] rounded-lg px-5 py-2.5">Submit</button>
                  </form>
              </div>
          </div>
      </div>
      </section>
  </template>
  
  <script>
  import axios from 'axios';
  import Swal from 'sweetalert2';
  
  export default {
      name: 'ForgetPassword',
      data() {
          return {
              form: {
                  password: '',
                  token: '',
              },
              confirm_password: '',
              CheckPassword: false
          }
      },
      mounted(){
        this.form.token = this.$route.params.token
      },
      methods: {
          onSubmit(){
            if (this.form.password === this.confirm_password) {
                axios.post(`/api/user/resetpassword`, this.form)
                  .then(res => {
                    if (res) {
                        Swal.fire({
                        title: 'เปลี่ยนรหัสผ่านสำเร็จ',
                        text: "ท่านได้ทำการเปลี่ยนรหัสผ่านสำเร็จแล้ว",
                        icon: 'success',
                        confirmButtonColor: '#3085d6',
                        confirmButtonText: 'ตกลง'
                            }).then((result) => {
                                if (result.isConfirmed) {
                                    this.$router.push({ name: 'LoginPage' })
                                }
                            })
                    }
                  })
              }
          },
          onCheckPassword(){
            if (this.form.password === this.confirm_password) {
                this.CheckPassword = false
            } else {
                this.CheckPassword = true
            }
          }
      }
  }
  </script>
  
  <style scoped>
  
  </style>