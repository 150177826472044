<template>
  
    <nav class="bg-white border-gray-200 px-2 sm:px-4 py-2.5 rounded dark:bg-gray-900">
      <div class="container flex flex-wrap items-center justify-between mx-auto">
        <a href="/management" class="flex items-center">
          <img src="@/assets/pharmacy_logo.png" class="w-16 h-auto mr-3 sm:h-full" alt="ITSC Logo" />
          <img src="@/assets/Medicine_CMU_Logo.png" class="w-14 h-auto mr-3 sm:h-full" alt="ITSC Logo" />
          <!-- <span class="text-black">EPIC 2023</span> -->
        </a>
        <button data-collapse-toggle="navbar-default" type="button" class="inline-flex items-center p-2 ml-3 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600" aria-controls="navbar-default" aria-expanded="false">
          <span class="sr-only">Open main menu</span>
          <svg class="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clip-rule="evenodd"></path></svg>
        </button>
        <div class="hidden w-full md:block md:w-auto" id="navbar-default">
          <ul class="flex flex-col p-4 mt-4 border border-gray-100 rounded-lg bg-gray-50 md:flex-row md:space-x-8 md:mt-0 md:text-sm md:font-medium md:border-0 md:bg-white">
            <li>
              <router-link to="/management" active-class="nav-active" class="block text-lg py-2 pl-3 pr-4 text-black rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0"> หน้าหลัก </router-link>
            </li>
            <!-- <li>
              <router-link to="/management/speaker" active-class="nav-active" class="block text-lg py-2 pl-3 pr-4 text-black rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0"> วิทยากร </router-link>
            </li> -->
            <li>
              <router-link to="/management/payment" active-class="nav-active" class="block text-lg py-2 pl-3 pr-4 text-black rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0"> หลักฐานการชำระเงิน </router-link>
            </li>
            <li>
              <router-link to="/management/user" active-class="nav-active" class="block text-lg py-2 pl-3 pr-4 text-black rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0"> ผู้ลงทะเบียน </router-link>
            </li>
            <li>
              <router-link to="/management/uploadpayment" active-class="nav-active" class="block text-lg py-2 pl-3 pr-4 text-black rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0"> อัพโหลดสลิป </router-link>
            </li>
            <li>
              <router-link to="/management/video" active-class="nav-active" class="block text-lg py-2 pl-3 pr-4 text-black rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0"> จัดการลิ้งค์ถ่ายทอดสด </router-link>
            </li>
            <!-- <li>
              <router-link to="/management/conference" active-class="nav-active" class="block text-lg py-2 pl-3 pr-4 text-black rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0"> ถ่ายทอดสด </router-link>
            </li> -->
            <li>
              <button @click="onLogout" class="text-white text-lg border bg-[#FFB11D] rounded-md px-3 hover:text-black hover:bg-white">ออกจากระบบ</button>
            </li>
          </ul>
        </div>
      </div>
    </nav>
    
    </template>
    
    <script>
    import axios from 'axios';
    export default {
        name: "AdminHeader",
        methods: {
          onLogout() {
            axios.post(`/api/login/logout`)
              .then(res => {
                if (res.status == 200) {
                  window.location.reload();
                }
              })
          }
        }
    }
    </script>
    
    <style scoped>
     .nav-active {
      @apply text-blue-700 border-b-2 border-blue-700
     }
    </style>