<template>
    <div>
      <PDFViewer
        :source="base64"
        style="height: 100vh; width: 100vw"
      />
    </div>
</template>
  
<script>
import PDFViewer from 'pdf-viewer-vue';
import axios from 'axios';
  
export default {
    name: 'PdfViewer',
    components: {
      PDFViewer,
    },
    data() {
      return {
        base64: '',
      }
    },
    mounted() {
        axios.get(`/api/schedule/${this.$route.params.id}`)
            .then(res => {
                this.base64 = res.data.sc_document
            })
    }
}
</script>