import { createApp } from 'vue'
import App from './App.vue'
import createRouter from './router'
import vueVimeoPlayer from 'vue-vimeo-player'
import './index.css'
import BackToTop from 'vue-backtotop'
import Countdown from 'vue3-flip-countdown'
import excel from 'vue-excel-export'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faPenToSquare, faTrashCan, faUser, faFileExcel } from '@fortawesome/free-regular-svg-icons'
import { faFacebook } from '@fortawesome/free-brands-svg-icons'
import { faListCheck, faPersonWalkingDashedLineArrowRight, faGlobe, faCircleArrowUp, faFilePdf, faUserTie, faCommentDots } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import Select2 from 'vue3-select2-component';
import VueSplide from '@splidejs/vue-splide'

library.add(faPenToSquare)
library.add(faTrashCan)
library.add(faListCheck)
library.add(faPersonWalkingDashedLineArrowRight)
library.add(faGlobe)
library.add(faCircleArrowUp)
library.add(faFilePdf)
library.add(faFacebook)
library.add(faUser)
library.add(faFileExcel)
library.add(faUserTie)
library.add(faCommentDots)

createApp(App)
    .use(createRouter())
    .use(vueVimeoPlayer)
    .use(BackToTop)
    .use(Countdown)
    .use(excel)
    .use(VueSplide)
    .component('Select2', Select2)
    .component('font-awesome-icon', FontAwesomeIcon)
    .mount('#app')
