<template>
  <section class="bg-[url('@/assets/bg-website.jpg')] bg-no-repeat bg-cover bg-center">
    <div class="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
        <h3 class="flex items-center mb-6 text-white">
            EPIC 2023
        </h3>
        <div class="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
            <div class="p-6 space-y-4 md:space-y-6 sm:p-8">
                <h1 class="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                    Sign in to your account
                </h1>
                <form class="space-y-4 md:space-y-6" @submit.prevent="onSubmit">
                    <div>
                        <label for="email" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Your email</label>
                        <input v-model="form.u_email" type="email" name="email" id="email" class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-[#2563eb] focus:border-primary-[#2563eb] block w-full p-2.5" placeholder="name@company.com" required>
                    </div>
                    <div>
                        <label for="password" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Password</label>
                        <input v-model="form.u_password" type="password" name="password" id="password" placeholder="••••••••" class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-[#2563eb] focus:border-primary-[#2563eb] block w-full p-2.5" required>
                    </div>
                    <!-- <div class="flex items-center justify-between">
                        <div class="flex items-start">
                            <div class="flex items-center h-5">
                                <input id="remember" aria-describedby="remember" type="checkbox" class="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-primary-[#2563eb] dark:ring-offset-gray-800" required="">
                            </div>
                            <div class="ml-3 text-sm">
                                <label for="remember" class="text-gray-500 dark:text-gray-300">Remember me</label>
                            </div>
                        </div>
                        <a href="#" class="text-sm font-medium text-primary-[#2563eb] hover:underline dark:text-primary-500">Forgot password?</a>
                    </div> -->
                    <button type="submit" class="w-full text-white bg-[#2563eb] rounded-lg px-5 py-2.5">Sign in</button>
                    <p class="flex justify-end text-sm font-light text-gray-500">
                        <a href="/forgetpassword" class="font-medium text-primary-[#2563eb] hover:underline">Forget Password?</a>
                    </p>
                    <p class="text-sm font-light text-gray-500 dark:text-gray-400">
                        Don’t have an account yet? <a href="/registertype" class="font-medium text-primary-[#2563eb] hover:underline">Sign up</a>
                    </p>
                </form>
            </div>
        </div>
    </div>
    </section>
</template>

<script>
import axios from 'axios';
import Swal from 'sweetalert2';

export default {
    name: 'Login',
    data() {
        return {
            form: {
                u_email: '',
                u_password: ''
            },
            isLogin: ''
        }
    },
    mounted(){
        axios.get(`/api/login`).then(res => {
            if (res.data.status === true) {
                this.$router.push({ name: 'Home' })
            } 
        });
    },
    methods: {
        onSubmit(){
            axios.post(`/api/login`, this.form)
                .then(res => {
                    if ( res.data.status == true ) {
                        if ( res.data.docs.u_status == 'false' ) {
                            this.$router.push({ name: 'PaymentInfo' })
                        } else {
                            this.$router.push({ name: 'Home' })
                        }
                    } else {
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: 'Email or Password went wrong!',
                        })
                    }
                })
        }
    }
}
</script>

<style scoped>

</style>