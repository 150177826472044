<template>
    <div>
        <AdminHeader></AdminHeader>
        <div class="container mx-auto">
            <div class="px-6 py-6 lg:px-8">
                            <h3 class="mb-4 text-xl font-medium text-gray-900 ">เพิ่มกำหนดการ</h3>
                            <form @submit.prevent="onUpdate" class="space-y-4">
                                <div>
                                    <label for="sc_title" class="block mb-2 text-sm font-medium text-gray-900 ">หัวข้อ</label>
                                    <input required v-model="form.sc_title" type="text" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
                                </div>
                                <div>
                                    <label for="sc_speaker" class="block mb-2 text-sm font-medium text-gray-900 ">วิทยากร</label>
                                    <select required @change="onHandleSpeaker($event.target.value)" name="sc_speaker" id="sc_speaker" class="w-full bg-gray-50 border border-gray-300 rounded-lg text-sm">
                                        <option value="">เลือกวิทยากร</option>
                                        <option v-for="(item, index) in speakerList" :key="index" :value="item._id"> {{ item.s_firstname }} {{ item.s_lastname }} </option>
                                    </select>
                                </div>
                                <div>
                                    <label for="sc_document" class="block mb-2 text-sm font-medium text-gray-900 ">เอกสารประกอบ</label>
                                    <input accept=".pdf" @change="onHandleFile($event)" type="file" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
                                </div>
                                <div>
                                    <label for="sc_date" class="block mb-2 text-sm font-medium text-gray-900 ">วันที่</label>
                                    <Datepicker required v-model="form.sc_date" :locale="th" input-format="dd/MM/yyyy" class="w-full bg-gray-50 border border-gray-300 rounded-lg text-sm" />
                                </div>
                                <div class="grid grid-cols-2 gap-2">
                                    <div>
                                        <label for="sc_time_from" class="block mb-2 text-sm font-medium text-gray-900 ">เริ่มต้น</label>
                                        <input required type="time" class="w-full bg-gray-50 border border-gray-300 rounded-lg text-sm" v-model="form.sc_time_from">
                                    </div>
                                    <div>
                                        <label for="sc_time_to" class="block mb-2 text-sm font-medium text-gray-900 ">สิ้นสุด</label>
                                        <input required type="time" class="w-full bg-gray-50 border border-gray-300 rounded-lg text-sm" v-model="form.sc_time_to">
                                    </div>
                                </div>
                                <button type="submit" class="w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">บันทึก</button>
                            </form>
                        </div>
        </div> 
    </div>
</template>

<script setup>
import Datepicker from 'vue3-datepicker'
</script>

<script>
import AdminHeader from '@/components/Admin/AdminHeader.vue';
import axios from 'axios';
import { th } from 'date-fns/locale';

export default {
    name: 'SpeakerEdit',
    components: {
        AdminHeader
    },
    data() {
        return {
            speakerList: '',
            form: {
                sc_title: '',
                sc_date: new Date(),
                sc_time_from: '',
                sc_time_to: '',
                sc_speaker: '',
                sc_image: '',
                sc_document: ''
            },
            th: th
        }
    },
    mounted(){
        this.onGetData();
        this.onGetSpeaker();
    },
    methods: {
        onGetData(){
            axios.get(`/api/schedule/${this.$route.params.id}`)
                .then(res => {
                    this.form = res.data;
                    this.form.sc_date = new Date(res.data.sc_date)
                })
        },
        onHandleSpeaker(value){
            this.speakerList.forEach(element => {
                if ( element._id == value ) {
                    this.form.sc_speaker = `${element.s_firstname} ${element.s_lastname}`;
                    this.form.sc_image = element.s_image;
                } else {
                    this.form.sc_speaker = ''
                    this.form.sc_image = ''
                }
            });
        },
        onHandleFile(event){
            const file = event.target.files[0];
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                this.form.sc_document = reader.result;
            };
                reader.onerror = (error) => {
                    console.log('Error: ', error);
            };            
        },
        onGetSpeaker(){
            axios.get(`/api/speaker`)
                .then(response => {
                    this.speakerList = response.data;
                })
        },
        onUpdate(){
            axios.put(`/api/schedule/${this.$route.params.id}`, this.form)
                .then(res => {
                    if (res.status === 200){
                        this.$router.push({name: 'SchedulePage'})
                    }
                })
        }
    }
}
</script>

<style>
    
</style>