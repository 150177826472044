<template>
    <div class="bg-slate-300 min-h-screen">
        <AdminHeader></AdminHeader>
        <div class="container mx-auto mt-10">
            <div class="flex justify-end items-center mb-3 space-x-2">
                <div>   
                    <input @keyup="onSearch($event.target.value)" placeholder="ค้นหาจากชื่อ" type="text" class="rounded-md">
                </div>
                <export-excel
                    :data="userExport" name="RegisterList.xls">
                    <button class="bg-blue-500 px-3 py-1 rounded-md text-white hover:bg-blue-700">Download Excel <font-awesome-icon :icon="['far', 'file-excel']" /></button>
                </export-excel>
            </div>
            <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
                <table class="w-full text-sm text-left text-gray-500">
                    <thead class="text-xs text-gray-700 uppercase bg-gray-50">
                        <tr>
                            <th scope="col" class="px-6 py-3 cursor-pointer" @click="onSortName()">
                                ชื่อ - นามสกุล
                            </th>
                            <th scope="col" class="px-6 py-3">
                                อีเมล์
                            </th>
                            <th scope="col" class="px-6 py-3">
                                สังกัด / หน่วยงาน
                            </th>
                            <th scope="col" class="px-6 py-3">
                                ประเภทการลงทะเบียน
                            </th>
                            <th scope="col" class="px-6 py-3 cursor-pointer" @click="onSortStatus()">
                                สถานะการชำระเงิน
                            </th>
                            <th scope="col" class="px-6 py-3 text-center w-1/6">
                                จัดการข้อมูล
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="bg-white border-b hover:bg-gray-200" v-for="(item, index) in userPerpage" :key="index">
                            <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap ">
                                {{ item.u_firstname }} {{ item.u_lastname }}
                            </th>
                            <td class="px-6 py-4">
                                {{ item.u_email }}
                            </td>
                            <td class="px-6 py-4">
                                {{ item.u_agency }}
                            </td>
                            <td class="px-6 py-4">
                                <span v-if="item.u_type == 'online'"> Online </span>
                                <span v-if="item.u_type == 'onsite'" class="text-white bg-blue-700 font-bold border-2 rounded-xl border-blue-700 px-3"> Onsite </span>
                            </td>
                            <td class="px-6 py-4">
                                <span v-if="item.u_status == 'false'" class="text-red-500"> ยังไม่ได้ชำระ </span>
                                <span v-if="item.u_status == 'pending'" class="text-orange-500"> รอตรวจสอบ </span>
                                <span v-if="item.u_status == 'true'" class="text-white bg-green-500 font-bold border-2 rounded-xl border-green-500 px-3"> ชำระแล้ว </span>
                            </td>
                            <td class="px-6 py-4 text-center space-x-2">
                                <button @click="this.$router.push({ path: `/management/user/edit/${item._id}` })" class="border bg-blue-700 py-1 px-2 rounded-md hover:bg-blue-400"><font-awesome-icon color="white" icon="fa-regular fa-pen-to-square" /></button>
                                <button @click="onDelete(item._id)" class="border bg-red-700 py-1 px-2 rounded-md hover:bg-red-400"><font-awesome-icon color="white" icon="fa-regular fa-trash-can" /></button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            
            <div class="flex justify-between items-center py-5">
                <p class="ml-3">จำนวนทั้งหมด : {{ totalUser }}</p>
                <nav class="mt-3">
                    <ul class="inline-flex -space-x-px" v-for="index in Math.ceil(totalUser / perPage)" :key="index">
                        <li v-if="index === page">
                            <button @click="onChangePagination(index)" class="px-3 py-2 leading-tight text-white bg-blue-700 border border-gray-300 hover:bg-gray-100 hover:text-gray-700 rounded-md"> {{ index }} </button>
                        </li>
                        <li v-else>
                            <button @click="onChangePagination(index)" class="px-3 py-2 leading-tight text-white bg-gray-400 border border-gray-300 hover:bg-gray-100 hover:text-gray-700 rounded-md"> {{ index }} </button>
                        </li>
                    </ul>
                </nav>
            </div>

        </div>
    </div>
</template>

<script>
import AdminHeader from '@/components/Admin/AdminHeader.vue';
import axios from 'axios';
import Swal from 'sweetalert2';

export default {
    name: 'UserPage',
    components: {
        AdminHeader
    },
    data() {
        return {
            userList: '',
            userPerpage: '',
            userExport: [],
            totalUser: 0,
            perPage: 20,
            page: 1,
        }
    },
    mounted() {
        this.onGetData();

        axios.get(`/api/login`).then(res => {
            if (res.data.status === true) {
                if ( res.data.docs.u_role != 'admin' ) {
                    this.$router.push({ name: 'AdminLogin' })
                }
            } else {
                this.$router.push({ name: 'AdminLogin' })
            }
        });
    },
    methods: {
        onGetData() {
            axios.get(`/api/user/`)
                .then(response => {
                    this.userList = response.data
                    this.userPerpage = this.userList.slice(0,20)
                    this.totalUser = response.data.length;

                    response.data.forEach(e => {
                        let status = ''
                        if (e.u_status == 'true') {
                            status = 'ชำระแล้ว'
                        }

                        if (e.u_status == 'pending') {
                            status = 'รอตรวจสอบ'
                        }

                        if (e.u_status == 'false') {
                            status = 'ยังไม่ได้ชำระ'
                        }

                        this.userExport.push({
                            'ชื่อ - นามสกุล' : `${e.u_firstname} ${e.u_lastname}`,
                            'อีเมล์': e.u_email,
                            'สังกัด / หน่วยงาน' : e.u_agency,
                            'เบอร์โทรศัพท์' : e.u_phone,
                            'อาชีพ' : e.u_career,
                            'เลขประจำตัว' : e.u_license,
                            'ประเภทการลงทะเบียน' : e.u_type,
                            'สถานะการชำระเงิน' : status
                        })
                    });

                
                })

            // axios.get(`/api/user/getuser/${this.page}`)
            //     .then(response => {
            //         this.userList = response.data;
            //     })
        },
        onDelete(id){
            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
                }).then((result) => {
                if (result.isConfirmed) {
                    axios.delete(`/api/user/${id}`).then(res => {
                        if ( res.status === 200 ) {
                            Swal.fire(
                                'Deleted!',
                                'Your file has been deleted.',
                                'success'
                            )

                            this.onGetData();
                        }
                    })
                }
            })
        },
        onSortName(){
            this.userList.sort(function(a, b){
                if(a.u_firstname < b.u_firstname) { return -1; }
                if(a.u_firstname > b.u_firstname) { return 1; }
                return 0;
            })

            this.page = 1
            this.userPerpage = this.userList.slice(0, 20)
        },
        onSortStatus(){
            this.userList.sort(function(a, b){
                if(a.u_status < b.u_status) { return -1; }
                if(a.u_status > b.u_status) { return 1; }
                return 0;
            })

            this.page = 1
            this.userPerpage = this.userList.slice(0, 20)
        },
        onChangePagination(page) {
            this.page = page;
            let item = ((page - 1) * 20) + 1
            this.userPerpage = this.userList.slice(item, (item + 20))
            // this.onGetData();
        },
        onSearch(text) {
            this.userPerpage = this.userList.filter((str) => { return str.u_firstname.includes(text) })
        }
    }
}
</script>

<style>
    
</style>