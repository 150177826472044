<template>
    <div>
        <AdminHeader></AdminHeader>
        <div class="flex justify-center align-middle items-center">
            <img src="@/assets/landing_img.jpg" alt="landing">
        </div>
    </div>
</template>

<script>
import AdminHeader from '@/components/Admin/AdminHeader.vue';
import axios from 'axios';

export default {
    name: 'AdminHome',
    components: {
        AdminHeader
    },
    data(){
        return {
        }
    },
    mounted(){
        axios.get(`/api/login`).then(res => {
            if (res.data.status === true) {
                if ( res.data.docs.u_role != 'admin' ) {
                    this.$router.push({ name: 'AdminLogin' })
                }
            } else {
                this.$router.push({ name: 'AdminLogin' })
            }
        });
    }
}
</script>

<style >
    
</style>