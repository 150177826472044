<template>
    <div>
        <Header></Header>
        <form @submit.prevent="onSubmit" class="min-w-screen min-h-screen flex items-center justify-center px-5 pb-10 pt-16">
            <div class="w-full mx-auto rounded-lg bg-white shadow-lg p-5 text-gray-700" style="max-width: 600px">
                <div class="w-full pt-1 pb-5">
                    <div class="bg-indigo-500 text-white overflow-hidden rounded-full w-20 h-20 -mt-16 mx-auto shadow-lg flex justify-center items-center">
                        <i class="mdi mdi-credit-card-outline text-3xl"></i>
                    </div>
                </div>
                <div class="mb-10">
                    <h1 class="text-center font-bold text-xl uppercase">แจ้งหลักฐานการชำระเงิน</h1>
                </div>
                <div class="mb-3">
                    <span class="font-bold text-sm mb-2 ml-1">ชื่อผู้โอน</span>
                    <div>
                        <input required v-model="form.p_transfer_name" class="w-full px-3 py-2 mb-1 border-2 border-gray-200 rounded-md focus:outline-none focus:border-indigo-500 transition-colors" placeholder="John Smith" type="text"/>
                    </div>
                </div>
                <div class="mb-3">
                    <span class="font-bold text-sm mb-2 ml-1">จำนวนเงินที่โอน</span>
                    <div class="w-full">
                        <CurrencyInput required class="w-full px-3 py-2 mb-1 border-2 border-gray-200 rounded-md focus:outline-none focus:border-indigo-500 transition-colors" v-model="form.p_transfer_amount" :options="{ currency: 'THB' }" />
                    </div>
                </div>
                <div class="mb-3">
                    <span class="font-bold text-sm mb-2 ml-1">ธนาคารที่โอน</span>
                    <div class="w-full">
                        <select required class="w-full px-3 py-2 mb-1 border-2 border-gray-200 rounded-md focus:outline-none focus:border-indigo-500 transition-colors" v-model="form.p_transfer_bank">
                            <option value="" selected>กรุณาเลือกธนาคาร</option>
                            <option v-for="(item, index) in bankList" :key="index" :value="item.official_name">{{ item.official_name }}</option>
                        </select>
                    </div>
                </div>
                <div class="grid grid-cols-2 gap-2 mb-3">
                    <div class="w-full">
                        <span class="font-bold text-sm mb-2 ml-1">วันที่โอน</span>
                        <div>
                            <Datepicker required v-model="form.p_transfer_date" :locale="th" input-format="dd/MM/yyyy" class="w-full bg-gray-50 border border-gray-300 rounded-lg" />
                        </div>
                    </div>
                    <div class="w-full">
                        <span class="font-bold text-sm mb-2 ml-1">เวลาที่โอน</span>
                        <div>
                            <input required v-model="form.p_transfer_time" type="time" class="w-full border border-gray-300">
                        </div>
                    </div>
                </div>
                <div class="mb-10">
                    <span class="font-bold mb-2 ml-1">ไฟล์แนบ</span>
                    <div>
                        <input required @change="handleFile($event)" type="file">
                    </div>
                </div>
                <div>
                    <button type="submit" class="block w-full max-w-xs mx-auto bg-indigo-500 hover:bg-indigo-700 focus:bg-indigo-700 text-white rounded-lg px-3 py-3 font-semibold"><i class="mdi mdi-lock-outline mr-1"></i> บันทึก </button>
                </div>
            </div>
        </form>
        <Footer></Footer>
    </div>
</template>

<script setup>
import Datepicker from 'vue3-datepicker'

</script>

<script>
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import { store } from '@/store';
import CurrencyInput from "../CurrencyInput.vue";
import { th } from 'date-fns/locale';
import axios from 'axios';

export default {
    name: 'PaymentNotify',
    components: {
        Header,
        Footer,
        CurrencyInput
    },
    data() {
        return {
            form: {
                user_id: store.user_id,
                p_transfer_name: '',
                p_transfer_amount: '',
                p_transfer_bank: '',
                p_transfer_date: '',
                p_transfer_time: '',
                p_transfer_slip: '',
            },
            th: th,
            bankList: {
                        "bbl": {
                        "code": "002",
                        "color": "#1e4598",
                        "official_name": "BANGKOK BANK PUBLIC COMPANY LTD.",
                        "nice_name": "Bangkok Bank",
                        "swift_code": "BKKBTHBK"
                        },
                        "kbank": {
                        "code": "004",
                        "color": "#138f2d",
                        "official_name": "KASIKORNBANK PUBLIC COMPANY LTD.",
                        "nice_name": "Kasikorn Bank",
                        "swift_code": "KASITHBK,"
                        },
                        "rbs": {
                        "code": "005",
                        "color": "#032952",
                        "official_name": "THE ROYAL BANK OF SCOTLAND PLC",
                        "nice_name": "Royal Bank of Scotland",
                        "swift_code": "RBOSGB2L"
                        },
                        "ktb": {
                        "code": "006",
                        "color": "#1ba5e1",
                        "official_name": "KRUNG THAI BANK PUBLIC COMPANY LTD.",
                        "nice_name": "Krungthai Bank",
                        "swift_code": "KRTHTHBK"
                        },
                        "jpm": {
                        "code": "008",
                        "color": "#321c10",
                        "official_name": "JPMORGAN CHASE BANK, NATIONAL ASSOCIATION",
                        "nice_name": "J.P. Morgan",
                        "swift_code": "CHASTHBXSEC"
                        },
                        "mufg": {
                        "code": "010",
                        "color": "#d61323",
                        "official_name": "THE BANK OF TOKYO-MITSUBISHI UFJ, LTD.",
                        "nice_name": "Bank of Tokyo-Mitsubishi UFJ",
                        "swift_code": "BOTKTHBX"
                        },
                        "tmb": {
                        "code": "011",
                        "color": "#1279be",
                        "official_name": "TMB BANK PUBLIC COMPANY LIMITED.",
                        "nice_name": "TMB Bank",
                        "swift_code": "TMBKTHBK"
                        },
                        "scb": {
                        "code": "014",
                        "color": "#4e2e7f",
                        "official_name": "SIAM COMMERCIAL BANK PUBLIC COMPANY LTD.",
                        "nice_name": "Siam Commercial Bank",
                        "swift_code": "SICOTHBK"
                        },
                        "citi": {
                        "code": "017",
                        "color": "#1583c7",
                        "official_name": "CITIBANK, N.A.",
                        "nice_name": "Citibank",
                        "swift_code": "CITITHBX"
                        },
                        "smbc": {
                        "code": "018",
                        "color": "#a0d235",
                        "official_name": "SUMITOMO MITSUI BANKING CORPORATION",
                        "nice_name": "Sumitomo Mitsui Banking Corporation",
                        "swift_code": "SMBCTHBK"
                        },
                        "sc": {
                        "code": "020",
                        "color": "#0f6ea1",
                        "official_name": "STANDARD CHARTERED BANK (THAI) PUBLIC COMPANY LIMITED",
                        "nice_name": "Standard Chartered (Thai)",
                        "swift_code": "SCBLTHBX"
                        },
                        "cimb": {
                        "code": "022",
                        "color": "#7e2f36",
                        "official_name": "CIMB THAI BANK PUPBLIC COMPANY LTD.",
                        "nice_name": "CIMB Thai Bank",
                        "swift_code": "UBOBTHBK"
                        },
                        "uob": {
                        "code": "024",
                        "color": "#0b3979",
                        "official_name": "UNITED OVERSEAS BANK (THAI) PUBLIC COMPANY LIMITED",
                        "nice_name": "United Overseas Bank (Thai)",
                        "swift_code": "UOVBTHBK"
                        },
                        "bay": {
                        "code": "025",
                        "color": "#fec43b",
                        "official_name": "BANK OF AYUDHYA PUBLIC COMPANY LTD.",
                        "nice_name": "Bank of Ayudhya (Krungsri)",
                        "swift_code": "AYUDTHBK"
                        },
                        "mega": {
                        "code": "026",
                        "color": "#815e3b",
                        "official_name": "MEGA INTERNATIONAL COMMERCIAL BANK PUBLIC COMPANY LIMITED",
                        "nice_name": "Mega International Commercial Bank",
                        "swift_code": "ICBCTHBKBNA"
                        },
                        "boa": {
                        "code": "027",
                        "color": "#e11e3c",
                        "official_name": "BANK OF AMERICA, NATIONAL ASSOCIATION",
                        "nice_name": "Bank of America",
                        "swift_code": "BOFATH2X"
                        },
                        "cacib": {
                        "code": "028",
                        "color": "#0e765b",
                        "official_name": "CREDIT AGRICOLE CORPORATE AND INVESTMENT BANK",
                        "nice_name": "Crédit Agricole",
                        "swift_code": "AGRITHB1"
                        },
                        "gsb": {
                        "code": "030",
                        "color": "#eb198d",
                        "official_name": "THE GOVERNMENT SAVINGS BANK",
                        "nice_name": "Government Savings Bank",
                        "swift_code": "GSBATHBK"
                        },
                        "hsbc": {
                        "code": "031",
                        "color": "#fd0d1b",
                        "official_name": "THE HONGKONG AND SHANGHAI BANKING CORPORATION LTD.",
                        "nice_name": "Hongkong and Shanghai Banking Corporation",
                        "swift_code": "HSBCTHBK"
                        },
                        "db": {
                        "code": "032",
                        "color": "#0522a5",
                        "official_name": "DEUTSCHE BANK AG.",
                        "nice_name": "Deutsche Bank",
                        "swift_code": "DEUTTHBK"
                        },
                        "ghb": {
                        "code": "033",
                        "color": "#f57d23",
                        "official_name": "THE GOVERNMENT HOUSING BANK",
                        "nice_name": "Government Housing Bank",
                        "swift_code": "GOHUTHB1"
                        },
                        "baac": {
                        "code": "034",
                        "color": "#4b9b1d",
                        "official_name": "BANK FOR AGRICULTURE AND AGRICULTURAL COOPERATIVES",
                        "nice_name": "Bank for Agriculture and Agricultural Cooperatives",
                        "swift_code": "BAABTHBK"
                        },
                        "mb": {
                        "code": "039",
                        "color": "#150b78",
                        "official_name": "MIZUHO BANK, LTD.",
                        "nice_name": "Mizuho Bank",
                        "swift_code": "MHCBTHBK"
                        },
                        "tbank": {
                        "code": "065",
                        "color": "#fc4f1f",
                        "official_name": "THANACHART BANK PUBLIC COMPANY LTD.",
                        "nice_name": "Thanachart Bank",
                        "swift_code": "THBKTHBK"
                        },
                        "bnp": {
                        "code": "045",
                        "color": "#14925e",
                        "official_name": "BNP PARIBAS",
                        "nice_name": "BNP Paribas",
                        "swift_code": "BNPATHBK"
                        },
                        "ibank": {
                        "code": "066",
                        "color": "#184615",
                        "official_name": "ISLAMIC BANK OF THAILAND",
                        "nice_name": "Islamic Bank of Thailand",
                        "swift_code": "TIBTTHBK"
                        },
                        "tisco": {
                        "code": "067",
                        "color": "#12549f",
                        "official_name": "TISCO BANK PUBLIC COMPANY LIMITED",
                        "nice_name": "Tisco Bank",
                        "swift_code": "TFPCTHB1"
                        },
                        "kk": {
                        "code": "069",
                        "color": "#199cc5",
                        "official_name": "KIATNAKIN BANK PUBLIC COMPANY LIMITED",
                        "nice_name": "Kiatnakin Bank",
                        "swift_code": "KKPBTHBK"
                        },
                        "icbc": {
                        "code": "070",
                        "color": "#c50f1c",
                        "official_name": "INDUSTRIAL AND COMMERCIAL BANK OF CHINA (THAI) PUBLIC COMPANY LIMITED",
                        "nice_name": "Industrial and Commercial Bank of China (Thai)",
                        "swift_code": "ICBKTHBK"
                        },
                        "tcrb": {
                        "code": "071",
                        "color": "#0a4ab3",
                        "official_name": "THE THAI CREDIT RETAIL BANK PUBLIC COMPANY LIMITED",
                        "nice_name": "Thai Credit Retail Bank",
                        "swift_code": "THCETHB1"
                        },
                        "lhb": {
                        "code": "073",
                        "color": "#6d6e71",
                        "official_name": "LAND AND HOUSES BANK PUBLIC COMPANY LIMITED",
                        "nice_name": "Land and Houses Bank",
                        "swift_code": "LAHRTHB2"
                        },
                        "ttb": {
                        "code": "076",
                        "color": "#ecf0f1",
                        "official_name": "TMBTHANACHART BANK PUBLIC COMPANY LIMITED",
                        "nice_name": "TMBThanachart Bank",
                        "swift_code": "TMBKTHBK"
                        }
            },
            slip_upload: ''
        }
    },
    mounted() {
        this.onHandleLogin();
        this.onCheckPayment();
    },
    methods: {
        onHandleLogin(){
            axios.get(`/api/login`)
            .then(res => {
                if ( res.data.status !== true ) {
                    this.$router.push({ name: 'Home' })
                } 
            })
        },
        handleFile(event){
            const file = event.target.files[0];
            const reader = new FileReader()
            reader.onloadend = () => {
                this.form.p_transfer_slip = reader.result;
            }
            reader.readAsDataURL(file);
            // const reader = new FileReader();
            // reader.readAsDataURL(file);
            // reader.onload = () => {
            //     this.form.p_transfer_slip = reader.result;
            // };
            //     reader.onerror = (error) => {
            //         console.log('Error: ', error);
            // };  
        },
        onSubmit(){
            axios.post(`/api/payment`, this.form)
                .then(res => {
                    if ( res.status === 200 ) {
                        this.$router.push({ name: 'PaymentPending' })
                    }
                })
        },
        onCheckPayment(){
            axios.get(`/api/payment/user/${store.user_id}`)
                .then(res => {
                    if (res.data) {
                        if (res.data.p_status == 'false') {
                            this.$router.push({ name: 'PaymentPending'} )
                        }
                    }
                })
        }
    }
}
</script>

<style scoped>
    
</style>