import { createRouter, createWebHistory } from 'vue-router/dist/vue-router.esm-bundler'
import Home from './views/Home.vue'
import Login from './views/Login.vue'
import Conference from './views/ConferenceRoom.vue'
import PaymentInfo from './views/Payment/PaymentInfo.vue'
import PaymentNotify from './views/Payment/PaymentNotify.vue'
import PageNotFound from './views/PageNotFound.vue'
// import ContactUs from './views/ContactUs.vue'
import LoginPage from './views/Login.vue'
// import RegisterPage from './views/Register.vue'
import ProfilePage from './views/Profile.vue'
import RegisterType from './views/RegisterType.vue'
import AdminHome from './views/Admin/AdminHome.vue'
import SpeakerPage from './views/Admin/Speaker/SpeakerPage.vue'
import SpeakerEdit from './views/Admin/Speaker/SpeakerEdit.vue'
import UserPage from './views/Admin/User/UserPage.vue'
import UserEdit from './views/Admin/User/UserEdit.vue'
// import AdminChat from './views/Admin/AdminChat.vue'
import AdminConference from './views/Admin/AdminConference.vue'
import SchedulePage from './views/Admin/Schedule/SchedulePage.vue'
import ScheduleEdit from './views/Admin/Schedule/ScheduleEdit.vue'
import PaymentPage from './views/Admin/Payment/PaymentPage.vue'
import PaymentDetail from './views/Admin/Payment/PaymentDetail.vue'
import UploadPayment from './views/Admin/Payment/UploadPayment.vue'
import VideoManage from './views/Admin/VideoManage.vue'
import PaymentPending from './views/Payment/PaymentPending.vue'
import PdfView from './views/PdfViewer.vue';
import AdminLogin from './views/Admin/AdminLogin.vue';
import DocumentPage from './views/DocumentPage.vue';
import ForgetPassword from './views/ForgetPassword.vue';
import ResetPassword from './views/ResetPassword.vue';
// import Replay from './views/Replay.vue'

export default () => createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      name: 'Home',
      component: Home,
    },
    {
      path: '/login',
      name: 'Login',
      component: Login,
    },
    {
      path: '/conference/:video_id',
      name: 'Conference',
      component: Conference,
    },
    {
      path: '/paymentinfo',
      name: 'PaymentInfo',
      component: PaymentInfo,
    },
    // {
    //   path: '/replay',
    //   name: 'replay',
    //   component: Replay,
    // },
    {
      path: '/paymentnotify',
      name: 'PaymentNotify',
      component: PaymentNotify,
    },
    {
      path: '/paymentpending',
      name: 'PaymentPending',
      component: PaymentPending,
    },
    {
      path: '/pdfview/:id',
      name: 'PdfView',
      component: PdfView,
    },
    // {
    //   path: '/contactus',
    //   name: 'ContactUs',
    //   component: ContactUs,
    // },
    {
      path: '/document',
      name: 'DocumentPage',
      component: DocumentPage,
    },
    {
      path: '/login',
      name: 'LoginPage',
      component: LoginPage,
    },
    {
      path: '/profile',
      name: 'profile',
      component: ProfilePage,
    },
    {
      path: '/forgetpassword',
      name: 'ForgetPassword',
      component: ForgetPassword,
    },
    {
      path: '/resetpassword/:token',
      name: 'ResetPassword',
      component: ResetPassword,
    },
    // {
    //   path: '/register/:u_type',
    //   name: 'RegisterPage',
    //   component: RegisterPage,
    // },
    {
      path: '/registertype',
      name: 'RegisterType',
      component: RegisterType,
    },
    {
      path: '/management',
      name: 'AdminHome',
      component: AdminHome,
    },
    {
      path: '/management/login',
      name: 'AdminLogin',
      component: AdminLogin,
    },
    {
      path: '/management/video',
      name: 'VideoManage',
      component: VideoManage,
    },
    {
      path: '/management/speaker',
      name: 'SpeakerPage',
      component: SpeakerPage,
    },
    {
      path: '/management/speaker/edit/:id',
      name: 'SpeakerEdit',
      component: SpeakerEdit,
    },
    {
      path: '/management/payment',
      name: 'PaymentPage',
      component: PaymentPage,
    },
    {
      path: '/management/payment/:id',
      name: 'PaymentDetail',
      component: PaymentDetail,
    },
    {
      path: '/management/uploadpayment',
      name: 'UploadPayment',
      component: UploadPayment,
    },
    {
      path: '/management/schedule',
      name: 'SchedulePage',
      component: SchedulePage,
    },
    {
      path: '/management/schedule/edit/:id',
      name: 'ScheduleEdit',
      component: ScheduleEdit,
    },
    {
      path: '/management/conference',
      name: 'AdminConference',
      component: AdminConference,
    },
    {
      path: '/management/user',
      name: 'UserPage',
      component: UserPage,
    },
    {
      path: '/management/user/edit/:id',
      name: 'UserEdit',
      component: UserEdit,
    },
    {
      path: "/:catchAll(.*)",
      name: "NotFound",
      component: PageNotFound,
      meta: {
        requiresAuth: false
      }
    }
  ],
})