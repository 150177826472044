<template>
    <div>
        <AdminHeader></AdminHeader>
        <div class="container mx-auto">
            <div class="px-6 py-6 lg:px-8">
                <h3 class="mb-4 text-xl font-medium text-gray-900 ">แก้ไขข้อมูลผู้ลงทะเบียน</h3>
                    <form @submit.prevent="onUpdate" class="space-y-4">
                                <div>
                                    <label for="email" class="block mb-2 text-sm font-medium text-gray-900 ">อีเมล์</label>
                                    <input required v-model="form.u_email" type="text" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
                                </div>
                                <div>
                                    <label for="firstname" class="block mb-2 text-sm font-medium text-gray-900 ">ชื่อจริง</label>
                                    <input required v-model="form.u_firstname" type="text" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
                                </div>
                                <div>
                                    <label for="lastname" class="block mb-2 text-sm font-medium text-gray-900 ">นามสกุล</label>
                                    <input required v-model="form.u_lastname" type="text" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
                                </div>
                                <div>
                                    <label for="agency" class="block mb-2 text-sm font-medium text-gray-900 ">สังกัด / หน่วยงาน</label>
                                    <input required v-model="form.u_agency" type="text" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
                                </div>
                                <div>
                                    <label for="career" class="block mb-2 text-sm font-medium text-gray-900 ">อาชีพ</label>
                                    <input required v-model="form.u_career" type="text" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
                                </div>
                                <div>
                                    <label for="phone" class="block mb-2 text-sm font-medium text-gray-900 ">เบอร์โทร</label>
                                    <input required v-model="form.u_phone" type="text" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
                                </div>
                                <div>
                                    <label for="type" class="block mb-2 text-sm font-medium text-gray-900 ">ประเภทการลงทะเบียน</label>
                                    <select v-model="form.u_type" class="w-full border-gray-300">
                                        <option value="online">Online</option>
                                        <option value="onsite">Onsite</option>
                                    </select>
                                </div>
                                <div class="flex justify-center">
                                    <button type="submit" class="w-1/2 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">บันทึก</button>
                                </div>
                            </form>
                        </div>
        </div> 
    </div>
</template>

<script>
import AdminHeader from '@/components/Admin/AdminHeader.vue';
import axios from 'axios';

export default {
    name: 'UserEdit',
    components: {
        AdminHeader
    },
    data() {
        return {
            form: {
                
            },
        }
    },
    mounted(){
        this.onGetData()
    },
    methods: {
        onGetData(){
            axios.get(`/api/user/${this.$route.params.id}`)
                .then(res => {
                    this.form = res.data;
                })
        },
        onUpdate(){
            axios.put(`/api/user/${this.$route.params.id}`, this.form)
                .then(res => {
                    if (res.status === 200){
                        this.$router.push({name: 'UserPage'})
                    }
                })
        }
    }
}
</script>

<style>
    
</style>