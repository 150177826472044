<template>
  
<nav class="bg-[#021734] border-gray-200 px-2 sm:px-4 py-2.5">
  <div class="container flex flex-wrap items-center justify-between mx-auto">
    <a href="/" class="flex items-center">
        <!-- <img src="@/assets/pharmacy_logo.png" class="w-16 h-auto mr-3 sm:h-full" alt="ITSC Logo" />
        <img src="@/assets/Medicine_CMU_Logo.png" class="w-14 h-auto mr-3 sm:h-full" alt="ITSC Logo" />
        <span class="text-white">EPIC 2023</span> -->
    </a>
    <div>
      <router-link v-if="!form.u_email" to="/login"><button class="lg:hidden text-black text-lg bg-[#FFB11D] rounded-md px-3 lg:text-white hover:text-[#FFB11D] hover:bg-white">เข้าสู่ระบบ</button></router-link>
      <!-- <button v-if="form.u_email" @click="onLogout" class="lg:hidden text-black text-lg bg-[#FFB11D] rounded-md px-3 lg:text-white hover:text-[#FFB11D] hover:bg-white">ออกจากระบบ</button> -->
      <div v-show="form.u_email" class="lg:hidden">
          <button id="navbarMobileButton" data-dropdown-toggle="navbarMobileHover" data-dropdown-trigger="hover" class="text-whit focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm px-4 py-2.5 text-center inline-flex items-center" type="button">
            <font-awesome-icon class="text-white" icon="fa-solid fa-user-tie" size="xl"/>
          </button>
        <!-- Dropdown menu -->
        <div id="navbarMobileHover" class="z-10 hidden bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700">
            <ul class="py-2 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="navbarMobileButton">
              <li>
                <a href="/profile" class="block px-4 py-2 hover:bg-gray-100">ข้อมูลส่วนตัว</a>
              </li>
              <li v-show="form.u_role === 'admin'">
                <a href="/management" class="block px-4 py-2 hover:bg-gray-200">จัดการข้อมูล (Admin)</a>
              </li>
              <li>
                <a @click="onLogout" class="block px-4 py-2 hover:bg-gray-100">ออกจากระบบ</a>
              </li>
            </ul>
        </div>
      </div>
      
      <button data-collapse-toggle="navbar-default" type="button" class="inline-flex items-center p-2 ml-3 text-sm text-gray-500 rounded-lg lg:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600" aria-controls="navbar-default" aria-expanded="false">
        <span class="sr-only">Open main menu</span>
        <svg class="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clip-rule="evenodd"></path></svg>
      </button>
    </div>
    <div class="hidden w-full lg:block lg:w-auto" id="navbar-default">
      <ul class="flex flex-col p-4 mt-4 border border-gray-100 rounded-lg bg-gray-50 lg:flex-row lg:space-x-8 lg:mt-0 lg:text-sm lg:font-medium lg:border-0 lg:bg-[#021734]">
        <li>
          <router-link to="/" active-class="nav-active" class="block text-lg py-2 pl-3 pr-4 text-black rounded hover:bg-gray-100 lg:text-white lg:hover:bg-transparent lg:border-0 lg:hover:text-[#91CFEE] lg:p-0"> หน้าหลัก </router-link>
        </li>
        <li>
          <a href="/#schedule-section" class="block text-lg py-2 pl-3 pr-4 text-black rounded hover:bg-gray-100 lg:text-white lg:hover:bg-transparent lg:border-0 lg:hover:text-[#91CFEE] lg:p-0"> กำหนดการ </a>
        </li>
        <!-- <li v-if="form.u_status == 'true' && form.u_type == 'online' || form.u_role == 'admin'">
          <router-link :to="'/Conference/' + video_id " active-class="nav-active" class="block text-lg py-2 pl-3 pr-4 text-black rounded hover:bg-gray-100 lg:text-white lg:hover:bg-transparent lg:border-0 lg:hover:text-[#91CFEE] lg:p-0"> รับชมการบรรยาย </router-link>
        </li> -->
        <li v-if="form.u_status == 'false' && form.u_role != 'admin'">
          <router-link to="/paymentinfo" active-class="nav-active" class="block text-lg py-2 pl-3 pr-4 text-black rounded hover:bg-gray-100 lg:text-white lg:hover:bg-transparent lg:border-0 lg:hover:text-[#91CFEE] lg:p-0"> ชำระเงิน </router-link>
        </li>
        <li v-if="form.u_status == 'pending'">
          <router-link to="/paymentinfo" active-class="nav-active" class="block text-lg py-2 pl-3 pr-4 text-black rounded hover:bg-gray-100 lg:text-white lg:hover:bg-transparent lg:border-0 lg:hover:text-[#91CFEE] lg:p-0"> สถานะการชำระเงิน </router-link>
        </li>
        <li>
          <a href="/#place-section" class="block text-lg py-2 pl-3 pr-4 text-black rounded hover:bg-gray-100 lg:text-white lg:hover:bg-transparent lg:border-0 lg:hover:text-[#91CFEE] lg:p-0"> สถานที่จัดงาน </a>
        </li>
        <!-- <li v-if="form.u_status">
          <router-link to="/replay" active-class="nav-active" class="block text-lg py-2 pl-3 pr-4 text-black rounded hover:bg-gray-100 lg:text-white lg:hover:bg-transparent lg:border-0 lg:hover:text-[#91CFEE] lg:p-0"> รับชมการบรรยาย </router-link>
        </li> -->
        <li>
          <router-link to="/document" active-class="nav-active" class="block text-lg py-2 pl-3 pr-4 text-black rounded hover:bg-gray-100 lg:text-white lg:hover:bg-transparent lg:border-0 lg:hover:text-[#91CFEE] lg:p-0"> เอกสารที่เกี่ยวข้อง </router-link>
        </li>
        <!-- <li v-if="!form.u_email">
          <router-link to="/registertype" active-class="nav-active" class="block text-lg py-2 pl-3 pr-4 text-black rounded hover:bg-gray-100 lg:text-white lg:hover:bg-transparent lg:border-0 lg:hover:text-[#91CFEE] lg:p-0"> ลงทะเบียน </router-link>
        </li> -->
        <li>
          <router-link v-if="!form.u_email" to="/login"><button class="text-black text-lg bg-[#FFB11D] rounded-md px-3 lg:text-white hover:text-[#FFB11D] hover:bg-white">เข้าสู่ระบบ</button></router-link>
          <div v-show="form.u_email">
            <button id="navbarButton" data-dropdown-toggle="navbarHover" data-dropdown-trigger="hover" class="text-whit focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-full border-white border-2 text-sm px-2 py-1 text-center inline-flex items-center" type="button">
              <font-awesome-icon class="text-white" icon="fa-solid fa-user-tie" size="lg"/>
            </button>
            <!-- Dropdown menu -->
            <div id="navbarHover" class="z-10 hidden bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700">
                <ul class="py-2 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="navbarButton">
                  <li>
                    <a href="/profile" class="block px-4 py-2 hover:bg-gray-200">ข้อมูลส่วนตัว</a>
                  </li>
                  <li v-show="form.u_role === 'admin'">
                    <a href="/management" class="block px-4 py-2 hover:bg-gray-200">จัดการข้อมูล (Admin)</a>
                  </li>
                  <li>
                    <a @click="onLogout" class="cursor-pointer block px-4 py-2 hover:bg-gray-200">ออกจากระบบ</a>
                  </li>
                </ul>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</nav>

</template>

<script setup>
import { onMounted } from 'vue'
import { initCollapses, initDropdowns } from 'flowbite'
import axios from 'axios';

onMounted(() => {
    initCollapses();
    initDropdowns();
})

</script>

<script>
export default {
    name: "Header",
    data(){
      return {
        form: '',
        video_id: ''
      }
    },
    mounted(){
      axios.get('/api/login')
        .then(res => {
          if (res.data.status === true) {
            this.form = res.data.docs;
          }
        })

    this.onGetVideo()
    },
    methods: {
      onGetVideo() {
            axios.get('/api/document/video')
                .then(res => {
                    this.video_id = res.data.d_link
                })
      },
      onLogout() {
        axios.post(`/api/login/logout`)
          .then(res => {
            if (res.status == 200) {
              window.location.reload();
            }
          })
      }
    }
}
</script>

<style scoped>
 .nav-active {
  @apply text-[#91CFEE] border-b-2 border-[#91CFEE]
 }
</style>