<template>
  <div class="bg-white croll-smooth">

    <Header></Header>

    <!-- HERO SECTION -->
    <section class="h-fitw-full bg-gray-300 mb-16 relative">
      <img src="@/assets/hero_1.png" alt="hero">
      <a href="/registertype"><button class="absolute bottom-1.5 left-10 md:bottom-7 md:left-28 lg:bottom-12 lg:left-44 border border-white px-3 lg:px-14 py-1 lg:py-3 rounded-lg text-white bg-gradient-to-bl from-blue-500 to-black hover:animate-bounce text-base md:text-2xl lg:text-3xl lg:font-bold">ลงทะเบียน</button></a>
    </section>

    <!-- ABOUT SECTION -->
    <section class="bg-gradient-to-bl from-blue-500 to-black">
      <div class="container mx-auto mb-16">
        <div class="flex justify-center flex-col items-center space-y-3 py-7">
          <h3 class="text-white">งานประชุม EPIC 2023</h3>
          <p class="text-xl text-white">เป็นงานประชุมโรคระบบหัวใจและหลอดเลือด ที่เกิดจากความร่วมมือระหว่างคณะเภสัชศาสตร์ มหาวิทยาลัยเชียงใหม่ และภาควิชาอายุรศาสตร์ หน่วยโรคหัวใจและหลอดเลือด คณะแพทยศาสตร์ มหาวิทยาลัยเชียงใหม่ โดยมุ่งเน้นให้ผู้เข้าร่วมประชุมมีความรู้ และทักษะเฉพาะทางด้านดูแลผู้ป่วยโรคหัวใจและหลอดเลือด เพื่อนำไปสู่การพัฒนาการรักษาและให้บริการผู้ป่วยอย่างมีประสิทธิภาพ</p>
        </div>
      </div>
    </section>
    
    <section class="container mx-auto mb-16">
      <div class="flex justify-center items-center mb-10">
        <div class="w-full lg:w-[75%] h-fit lg:h-24 bg-[#021734] rounded-lg">
          <div class="grid grid-cols-1 lg:grid-cols-2 gap-4 h-full">
            <div class="flex justify-center items-center p-3 lg:p-0 border-r-2">
              <span class="text-white text-xl lg:text-4xl"><b class="text-[#EC685C]">Onsite</b> จำกัด 250 ท่าน</span>
            </div>
            <div class="flex justify-center items-center p-3 lg:p-0">
              <span class="text-white text-xl lg:text-4xl"><b class="text-[#EC685C]">Online</b> ไม่จำกัดจำนวน</span>
            </div>
          </div>
        </div>
        <!-- <div class="w-full lg:w-1/2 h-fit lg:h-24 bg-[#021734] rounded-lg">
          <div class="grid grid-cols-1 lg:flex justify-between items-center align-middle flex-row gap-5 h-full p-5 lg:p-0">
            <span class="text-white text-3xl ml-9 md:ml-52 lg:ml-12 font-bold">จำนวนผู้ลงทะเบียน</span>
            <div class="flex justify-center items-center flex-col">
              <p class="text-transparent bg-clip-text bg-gradient-to-r from-purple-500 to-pink-500 tracking-wide text-4xl font-bold"> {{ countOnsite }} </p>
              <p class="text-white text-2xl font-normal">Onsite</p>
            </div>
            <div class="flex justify-center items-center flex-col lg:mr-10">
              <p class="text-transparent bg-clip-text bg-gradient-to-r from-purple-500 to-pink-500 tracking-wide text-4xl font-bold"> {{ countOnline }} </p>
              <p class="text-white text-2xl font-normal">Online</p>
            </div>
          </div>
        </div> -->
      </div>

      <vue3-flip-countdown deadline="2023-06-12 08:00:00" countdownSize="6rem" />
    </section>

    <!-- <div class="bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 h-40 mb-28">
      <div class="container mx-auto h-full">
        <div class="grid grid-cols-5 h-full">
          <div class="flex justify-center items-center align-middle h-full">
            <img src="@/assets/pharmacy_logo.png" alt="pharmacy_logo" class="w-36 h-auto">
          </div>
          <div class="flex justify-center items-center align-middle h-full">
            <img src="@/assets/pharmacy_logo.png" alt="pharmacy_logo" class="w-36 h-auto">
          </div>
          <div class="flex justify-center items-center align-middle h-full">
            <img src="@/assets/pharmacy_logo.png" alt="pharmacy_logo" class="w-36 h-auto">
          </div>
          <div class="flex justify-center items-center align-middle h-full">
            <img src="@/assets/pharmacy_logo.png" alt="pharmacy_logo" class="w-36 h-auto">
          </div>
          <div class="flex justify-center items-center align-middle h-full">
            <img src="@/assets/pharmacy_logo.png" alt="pharmacy_logo" class="w-36 h-auto">
          </div>
        </div>
      </div>
    </div> -->

    <!-- SPEACKER SECTION -->
    <!-- <section class="container mx-auto mb-28">
      <div class="flex justify-center mb-3">
        <h1 class="text-[#140526]">วิทยากร</h1>
      </div>
      <div class="grid grid-cols-1 gap-4 lg:grid-cols-3 my-10">
        <div class="bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 w-3/4 h-auto mx-auto">
          <svg class="w-full h-full p-10 text-gray-200" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" fill="currentColor" viewBox="0 0 640 512"><path d="M480 80C480 35.82 515.8 0 560 0C604.2 0 640 35.82 640 80C640 124.2 604.2 160 560 160C515.8 160 480 124.2 480 80zM0 456.1C0 445.6 2.964 435.3 8.551 426.4L225.3 81.01C231.9 70.42 243.5 64 256 64C268.5 64 280.1 70.42 286.8 81.01L412.7 281.7L460.9 202.7C464.1 196.1 472.2 192 480 192C487.8 192 495 196.1 499.1 202.7L631.1 419.1C636.9 428.6 640 439.7 640 450.9C640 484.6 612.6 512 578.9 512H55.91C25.03 512 .0006 486.1 .0006 456.1L0 456.1z"/></svg>
        </div>
        <div class="col-span-2">
          <h2 class="text-[#140526]">อาจารย์ ชื่อ วิทยากร</h2>
          <span class="text-[#140526]">รองผู้บังคับการ วชิราวุธวิทยาลัย</span>
          <p class="mt-5 text-3xl font-light text-[#140526]">Digital Mindset : People Transformation</p>
          <span class="text-[#140526]">Businesses often become known today through effective marketing. The marketing may be in the form of a regular news item or half column society news in the Sunday newspaper.</span>
        </div>
      </div>

      <div class="grid grid-cols-1 gap-4 lg:grid-cols-3 my-10">
        <div class="bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 w-3/4 h-auto mx-auto">
          <svg class="w-full h-full p-10 text-gray-200" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" fill="currentColor" viewBox="0 0 640 512"><path d="M480 80C480 35.82 515.8 0 560 0C604.2 0 640 35.82 640 80C640 124.2 604.2 160 560 160C515.8 160 480 124.2 480 80zM0 456.1C0 445.6 2.964 435.3 8.551 426.4L225.3 81.01C231.9 70.42 243.5 64 256 64C268.5 64 280.1 70.42 286.8 81.01L412.7 281.7L460.9 202.7C464.1 196.1 472.2 192 480 192C487.8 192 495 196.1 499.1 202.7L631.1 419.1C636.9 428.6 640 439.7 640 450.9C640 484.6 612.6 512 578.9 512H55.91C25.03 512 .0006 486.1 .0006 456.1L0 456.1z"/></svg>
        </div>
        <div class="col-span-2">
          <h2 class="text-[#140526]">อาจารย์ ชื่อ วิทยากร</h2>
          <span class="text-[#140526]">รองผู้บังคับการ วชิราวุธวิทยาลัย</span>
          <p class="mt-5 text-3xl font-light text-[#140526]">Digital Mindset : People Transformation</p>
          <span class="text-[#140526]">Businesses often become known today through effective marketing. The marketing may be in the form of a regular news item or half column society news in the Sunday newspaper.</span>
        </div>
      </div>
    </section> -->

    <!-- SCHEDULE SECTION -->
    <section class="container mx-auto mb-16" id="schedule-section">
      <div class="text-center">
        <h2 class="text-[#140526]">กำหนดการ & วิทยากร</h2>
        <p class="text-lg text-[#140526] my-5">วันที่ 12 - 16 มิถุนายน 2566 รูปแบบ Hybrid Meeting ณ โรงแรมเซ็นทารา ริเวอร์ไซด์ เชียงใหม่ โดยคณะเภสัชศาสตร์ มหาวิทยาลัยเชียงใหม่</p>
      </div>

      <Schedule></Schedule>
    </section>

    <section class="bg-gradient-to-bl from-blue-500 to-black">
      <div class="container mx-auto mb-16 p-4">
        <h3 class="text-white">อัตราการลงทะเบียน</h3>
                    <p class="text-white">สำหรับเภสัชกร บุคลากรทางการแพทย์และคณาจารย์ คณะเภสัชศาสตร์</p>
                    <div class="w-full">
                        <div class="grid grid-cols-1 file:gap-2 my-3">
                            <div class="border border-gray-400 col-span-2 rounded-sm ">
                                <div class="w-full grid grid-cols-4 p-5">
                                    <span class="col-span-2 w-full">  </span>
                                    <span class="text-center text-white"> Onsite <br> ณ โรงแรมเซ็นทารา ริเวอร์ไซด์ เชียงใหม่ </span>
                                    <span class="text-center text-white"> Online  </span>
                                </div>
                                <div class=" w-full bg-slate-300 grid grid-cols-4 p-5">
                                    <span class="col-span-2 w-full"> ภายในวันที่ 4 มิถุนายน 2566 </span>
                                    <span class="text-center"> 5,500 บาท  </span>
                                    <span class="text-center"> 3,500 บาท  </span>
                                </div>
                                <div class=" w-full grid grid-cols-4 p-5">
                                    <span class="col-span-2 w-full text-white"> หลังวันที่ 4 มิถุนายน 2566 </span>
                                    <span class="text-center text-white"> 6,000 บาท  </span>
                                    <span class="text-center text-white"> 4,000 บาท  </span>
                                </div>
                            </div>
                        </div>
                    </div>
      </div>
    </section>

    <section id="place-section" class="h-fit">
      <h2 class="text-[#140526] text-center mb-5">สถานที่จัดงาน โรงแรม Centara Riverside</h2>
      <div class="flex justify-center ">
        <a class="text-xl text-white bg-blue-700 hover:bg-[#FFB11D] hover:text-white px-5 py-2 rounded-md mb-5" href="/Document/ใบจองที่พัก_คณะเภสัชศาสตร์ มช._12-16June.pdf" target="_blank">ข้อมูลการจองโรงแรม</a>
      </div>
      <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-2 mb-16 px-2">
        <img @click="onSelectImage = '/slider/slider_1.jpg'" data-modal-target="popup-modal" data-modal-toggle="popup-modal" class="object-cover w-full h-full rounded-lg cursor-pointer" src="/slider/slider_1.jpg" />
        <img @click="onSelectImage = '/slider/slider_2.jpg'" data-modal-target="popup-modal" data-modal-toggle="popup-modal" class="object-cover w-full h-full rounded-lg cursor-pointer" src="/slider/slider_2.jpg" />
        <img @click="onSelectImage = '/slider/slider_3.jpg'" data-modal-target="popup-modal" data-modal-toggle="popup-modal" class="object-cover w-full h-full rounded-lg cursor-pointer" src="/slider/slider_3.jpg" />
        <img @click="onSelectImage = '/slider/slider_4.jpg'" data-modal-target="popup-modal" data-modal-toggle="popup-modal" class="object-cover w-full h-full rounded-lg cursor-pointer" src="/slider/slider_4.jpg" />
        <img @click="onSelectImage = '/slider/slider_5.jpg'" data-modal-target="popup-modal" data-modal-toggle="popup-modal" class="object-cover w-full h-full rounded-lg cursor-pointer" src="/slider/slider_5.jpg" />
      </div>

      <div id="popup-modal" tabindex="-1" class="fixed top-0 left-0 right-0 z-50 hidden p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] md:h-full">
          <div class="relative w-full h-full max-w-2xl md:h-auto">
              <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
                  <button type="button" class="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white" data-modal-hide="popup-modal">
                      <svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                      <span class="sr-only">Close modal</span>
                  </button>
                  <div class="p-6 text-center">
                      <img class="w-full h-auto" :src="onSelectImage" alt="modal image">
                  </div>
              </div>
          </div>
      </div>

    </section>

    <Footer></Footer>

    <back-to-top><font-awesome-icon color="orange" :icon="['fas', 'circle-arrow-up']" size="2xl" /></back-to-top>

  </div>
</template>

<script setup>
import { onMounted } from 'vue'
import { initModals } from 'flowbite'

onMounted(() => {
  initModals();
})
</script>

<script>
import Header from '../components/Header'
import Schedule from '../components/Schedule.vue'
import Footer from '../components/Footer.vue'
import axios from 'axios'

export default {
    name: 'Home',
    components: {
        Header,
        Schedule,
        Footer,
    },
    data() {
      return {
        onSelectImage: '',
        countOnline: 0,
        countOnsite: 0
      }
    },
    mounted(){
      this.onCountOnline();
      this.onCountOnsite();
    },
    methods: {
      onCountOnline() {
        axios.get(`/api/user/countonline`)
          .then(res => {
            this.countOnline = res.data;
          })
      },
      onCountOnsite() {
        axios.get(`/api/user/countonsite`)
          .then(res => {
            this.countOnsite = res.data;
          })
      },
    }
}
</script>

<style scoped>
.btn-to-top {
  width: 60px;
  height: 60px;
  padding: 10px 16px;
  border-radius: 50%;
  font-size: 22px;
  line-height: 22px;
}
</style>