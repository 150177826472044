<template >
    <div>
        <AdminHeader></AdminHeader>
        <div class="container mx-auto mt-10 flex w-[34rem] justify-center flex-col items-center bg-slate-100 space-y-3">
            <div class="flex flex-col px-5 w-full pt-5">
                <label for="p_name">ชื่อผู้โอน</label>
                <input :value="form.p_transfer_name" class="border border-gray-300 bg-gray-50 rounded-md" type="text" readonly>
            </div>
            <div class="flex flex-col px-5 w-full">
                <label for="p_name">ธนาคารที่โอน</label>
                <input :value="form.p_transfer_bank" class="border border-gray-300 rounded-md bg-gray-50" type="text" readonly>
            </div>
            <div class="flex flex-col px-5 w-full">
                <label for="p_name">จำนวนเงินที่โอน</label>
                <input :value="form.p_transfer_amount" class="border border-gray-300 rounded-md bg-gray-50" type="text" readonly>
            </div>
            <div class="flex flex-col px-5 w-full">
                <div class="grid grid-cols-2 gap-2">
                    <div>
                        <label for="p_name">วันที่โอน</label>
                        <input :value="DisplayDate(form.p_transfer_date)" class="border border-gray-300 rounded-md bg-gray-50" type="text" readonly>
                    </div>
                    <div>
                        <label for="p_name">เวลาที่โอน</label>
                        <input :value="form.p_transfer_time" class="border border-gray-300 rounded-md" type="text" readonly>
                    </div>
                </div>
            </div>
            <div class="flex flex-col px-5 w-full pb-5 justify-center items-center">
                <label>หลักฐานการชำระเงิน</label>
                <img class="w-96 h-auto" :src="form.p_transfer_slip" alt="slip payment">
            </div>
            <div class="flex px-5 w-full pb-5 justify-center items-center space-x-2">
                <button @click="this.$router.push({ name: 'PaymentPage'})" class="bg-orange-500 text-white px-3 py-1 rounded-sm hover:bg-white hover:border-orange-500 hover:text-orange-500">ย้อนกลับ</button>
                <button @click="onUpdate" class="bg-blue-500 text-white px-3 py-1 rounded-sm hover:bg-white hover:border-blue-500 hover:text-blue-500">ยืนยันข้อมูล</button>
            </div>
        </div>
    </div>
</template>

<script setup>

</script>

<script>
import AdminHeader from '@/components/Admin/AdminHeader.vue';
import axios from 'axios';
import moment from 'moment';

export default {
    name: 'PaymentDetail',
    components: {
        AdminHeader,
        
    },
    data() {
        return {
            form: '',
        }
    },
    mounted() {
        this.onGetDetail();
    },
    methods: {
        onGetDetail(){
            axios.get(`/api/payment/${this.$route.params.id}`)
                .then(res => {
                    if ( res.status === 200 ) {
                        this.form = res.data;
                    }
                })
        },
        DisplayDate(date) {
            return moment(date).format('DD/MM/yyyy');
        },
        onUpdate(){
            axios.put(`/api/payment/${this.$route.params.id}`, {
                p_status: true,
            }).then(res => {
                if (res.status === 200) {
                    this.$router.push({ name: 'PaymentPage' })
                }
            })
        }
    }
}
</script>

<style scoped>
    
</style>