<template>
  <div>
    <HeaderVue></HeaderVue>

    <section class="flex justify-center items-center align-middle h-screen bg-[url('@/assets/bg-website.jpg')] bg-cover bg-no-repeat bg-center">      
      <vimeo-player class="px-10 lg:px-0 -mt-44 lg:-mt-10 object-fill w-full h-auto flex justify-center" ref="player" :video-id="video_id" autoplay></vimeo-player>
    </section>

    <FooterVue></FooterVue>

    <!-- CHAT BUTTON SECTION -->
    <div class="fixed right-6 bottom-6 group"> 
        <button @click="onChatOpen()" type="button" class="flex items-center justify-center text-white bg-blue-700 rounded-full w-14 h-14 hover:bg-blue-800 dark:bg-blue-600 dark:hover:bg-blue-700 focus:ring-4 focus:ring-blue-300 focus:outline-none dark:focus:ring-blue-800">
          <font-awesome-icon icon="fa-solid fa-comment-dots" beat style="color: #ffffff" />
        </button>
    </div>

    <!-- CHAT SECTION -->
    <div class="fixed right-10 bottom-24 group"> 
        <div class="bg-white w-80 h-96 rounded-md chat-scroll overflow-y-auto" v-show="isChatOpen">
          
          <div class="w-full px-5 flex flex-col justify-between chat-sub">
            <div class="flex flex-col mt-5" v-for="(item, index) in chatList" :key="index">
              <div class="flex justify-end mb-4" v-if="item.c_name == this.user">
                <button v-if="userRole === 'admin'" @click="onDeleteChat(item._id)" class="mr-3"><font-awesome-icon color="red" icon="fa-regular fa-trash-can" /></button>
                <div
                  class="mr-2 py-3 px-4 bg-gray-400 rounded-bl-3xl rounded-tl-3xl rounded-tr-xl text-white"
                >
                <p> {{ item.c_message }} </p>
                <p class="text-[0.5rem] flex justify-end"> {{ item.c_name }} </p>
                </div>
              </div>
              <div class="flex justify-start mb-4" v-if="item.c_name != this.user">
                <div
                  class="ml-2 py-3 px-4 bg-blue-600 rounded-br-3xl rounded-tr-3xl rounded-tl-xl text-white"
                >
                <p> {{ item.c_message }} </p>
                <p class="text-[0.5rem] flex justify-start"> {{ item.c_name }} </p>
                </div>
                <button v-if="userRole === 'admin'" @click="onDeleteChat(item._id)" class="ml-3"><font-awesome-icon color="red" icon="fa-regular fa-trash-can" /></button>
              </div>
            </div>
            <div class="py-5">
              <input
                v-on:keyup.enter="onHandleChat"
                v-model="inputChat"
                class="w-full bg-gray-300 py-5 px-3 rounded-xl"
                type="text"
                placeholder="type your message here..."
              />
            </div>
          </div>
      
        </div>
    </div>

  </div>
</template>

<script>
import FooterVue from '../components/Footer.vue'
import HeaderVue from '../components/Header.vue'
import { io } from "socket.io-client";

const url = 'https://epic2023.virtual-event.cmu.ac.th';
// const url = 'http://localhost:3000'
const socket = io(url, {
  auth: {
    token: 'aantmod'
  }
});
import Swal from 'sweetalert2';
import axios from 'axios';

export default {
    name: 'ConferenceRoom',
    components: {
        HeaderVue,
        FooterVue
    },
    data() {
      return {
        isChatOpen: false,
        chatList: '',
        inputChat: '',
        user: '',
        userRole: '',
        video_id: this.$route.params.video_id
      }
    },
    created() {
      socket.on('pharmacy_message', () => {
        this.onGetChat()
      })
    },
    mounted(){
      this.onHandleLogin()
      this.onGetChat()
    },
    methods: {
      onHandleLogin(){
        axios.get(`/api/login`)
          .then(res => {
            if (res.data.status === true) {
              if ( res.data.docs.u_type == 'online' || res.data.docs.u_role == 'admin' ) {
                this.user = `${res.data.docs.u_firstname} ${res.data.docs.u_lastname}`;
                this.userRole = res.data.docs.u_role;
              } 
            } else {
                this.$router.push({ name: 'Home' })
            }
          })
      },
      onChatOpen() {
        if (this.isChatOpen) {
          this.isChatOpen = false
        } else {
          this.isChatOpen = true
        }
      },
      onGetChat(){
        axios.get(`/api/chat`)
          .then(res => {
            this.chatList = res.data;
          })
      },
      onHandleChat(){
        socket.emit('pharmacy_message', {
          c_name: this.user,
          c_message: this.inputChat
        })

        this.inputChat = '';
      },
      onDeleteChat(id){
            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
                }).then((result) => {
                if (result.isConfirmed) {
                    axios.delete(`/api/chat/${id}`)
                        .then(res => {
                            if (res.status == 200) {
                                this.onGetChat();
                            }
                        })
                }
            })
        }
    }
}
</script>

<style scoped>
.chat-scroll {
  overflow-y: scroll;
  position: relative;
  transform:rotateX(180deg);
                -moz-transform:rotateX(180deg); /* Mozilla */
                -webkit-transform:rotateX(180deg); /* Safari and Chrome */
                -ms-transform:rotateX(180deg); /* IE 9+ */
                -o-transform:rotateX(180deg); /* Opera */
}

.chat-sub {
  transform:rotateX(180deg);
                -moz-transform:rotateX(180deg); /* Mozilla */
                -webkit-transform:rotateX(180deg); /* Safari and Chrome */
                -ms-transform:rotateX(180deg); /* IE 9+ */
                -o-transform:rotateX(180deg); /* Opera */
}
</style>