<template >
    <div>
        <Header></Header>
            <section class="bg-[url('@/assets/bg-website.jpg')] h-52 bg-no-repeat bg-cover bg-center mb-5">
                <div class="container mx-auto py-16">
                    <h2 class="text-white text-center">เอกสารที่เกี่ยวข้อง</h2>
                </div>      
            </section>

            <div class="container mx-auto mb-10">
                <h3 class="text-black mb-3">เอกสารเกี่ยวกับการประชุม</h3>
                <div class="mb-5 border border-gray-500 px-5 py-3 rounded-lg bg-gradient-to-bl from-blue-500 to-black">
                    <ul class="max-w-md space-y-1 text-gray-500 list-inside ml-5">
                        <li class="flex items-center text-lg">
                            <a href="/Document/ว_105_จดหมายเชิญประชุม_epic.pdf" class="hover:text-blue-500" target="_blank">
                                <font-awesome-icon class="mr-1.5 text-yellow-300" :icon="['fas', 'file-pdf']" />
                                <span class="text-white hover:text-blue-500">จดหมายเชิญประชุม EPIC</span>
                            </a>
                        </li>
                        <li class="flex items-center text-lg">
                            <a href="/Document/หนังสืออนุมัติจัดประชุม_EPIC.pdf" class="hover:text-blue-500" target="_blank">
                                <font-awesome-icon class="mr-1.5 text-yellow-300" :icon="['fas', 'file-pdf']" />
                                <span class="text-white hover:text-blue-500">หนังสืออนุมัติจัดประชุม EPIC</span>
                            </a>
                        </li>
                        <li class="flex items-center text-lg">
                            <a href="/Document/โครงการการประชุม_EPIC_2023.pdf" class="hover:text-blue-500" target="_blank">
                                <font-awesome-icon class="mr-1.5 text-yellow-300" :icon="['fas', 'file-pdf']" />
                                <span class="text-white hover:text-blue-500">โครงการการประชุม EPIC 2023</span>
                            </a>
                        </li>
                        <li class="flex items-center text-lg">
                            <a href="/Document/อัตราค่าลงทะเบียน_epic.pdf" class="hover:text-blue-500" target="_blank">
                                <font-awesome-icon class="mr-1.5 text-yellow-300" :icon="['fas', 'file-pdf']" />
                                <span class="text-white hover:text-blue-500">อัตราการลงทะเบียน EPIC</span>
                            </a>
                        </li>
                    </ul>
                </div>

                <h3 class="text-black mb-3">เอกสารประกอบการบรรยาย</h3>
                    <div class="mb-5 border border-gray-500 px-5 py-3 rounded-lg bg-gradient-to-bl from-blue-500 to-black">
                        <ul class="max-w-md text-gray-500 list-inside ml-5" v-for="(item, index) in document" :key="index">
                            <li class="flex items-center text-lg mb-1">
                                <a :href="item.d_link" class="hover:text-blue-500" target="_blank">
                                    <font-awesome-icon class="mr-1.5 text-yellow-300" :icon="['fas', 'file-pdf']" />
                                    <span class="text-white hover:text-blue-500"> {{ item.d_title }} </span>
                                </a>
                            </li>
                        </ul>
                    </div>

                <!-- <h3 class="text-black mb-3">เอกสารประกอบการบรรยาย</h3>
                <div class="border border-gray-500 px-5 py-3 rounded-lg bg-gradient-to-bl from-blue-500 to-black">
                    <div class="">
                        <h5 class="font-medium text-white">วันที่ 12 มิถุนายน 2566</h5>
                        <ul class="max-w-md space-y-1 text-gray-500 list-inside mt-3 ml-5">
                            <li class="flex items-center text-lg">
                                <a href="/Document/หนังสืออนุมัติจัดประชุม_EPIC.pdf" class="hover:text-blue-500" target="_blank">
                                    <font-awesome-icon class="mr-1.5 text-yellow-300" :icon="['fas', 'file-pdf']" />
                                    <span class="text-white hover:text-blue-500">หนังสืออนุมัติจัดประชุม EPIC</span>
                                </a>
                            </li>
                            <li class="flex items-center text-lg">
                                <a href="/Document/หนังสืออนุมัติจัดประชุม_EPIC.pdf" class="hover:text-blue-500" target="_blank">
                                    <font-awesome-icon class="mr-1.5 text-yellow-300" :icon="['fas', 'file-pdf']" />
                                    <span class="text-white hover:text-blue-500">หนังสืออนุมัติจัดประชุม EPIC</span>
                                </a>
                            </li>
                            <li class="flex items-center text-lg">
                                <a href="/Document/หนังสืออนุมัติจัดประชุม_EPIC.pdf" class="hover:text-blue-500" target="_blank">
                                    <font-awesome-icon class="mr-1.5 text-yellow-300" :icon="['fas', 'file-pdf']" />
                                    <span class="text-white hover:text-blue-500">หนังสืออนุมัติจัดประชุม EPIC</span>
                                </a>
                            </li>
                            <li class="flex items-center text-lg">
                                <a href="/Document/หนังสืออนุมัติจัดประชุม_EPIC.pdf" class="hover:text-blue-500" target="_blank">
                                    <font-awesome-icon class="mr-1.5 text-yellow-300" :icon="['fas', 'file-pdf']" />
                                    <span class="text-white hover:text-blue-500">หนังสืออนุมัติจัดประชุม EPIC</span>
                                </a>
                            </li>
                            <li class="flex items-center text-lg">
                                <a href="/Document/หนังสืออนุมัติจัดประชุม_EPIC.pdf" class="hover:text-blue-500" target="_blank">
                                    <font-awesome-icon class="mr-1.5 text-yellow-300" :icon="['fas', 'file-pdf']" />
                                    <span class="text-white hover:text-blue-500">หนังสืออนุมัติจัดประชุม EPIC</span>
                                </a>
                            </li>
                            <li class="flex items-center text-lg">
                                <a href="/Document/หนังสืออนุมัติจัดประชุม_EPIC.pdf" class="hover:text-blue-500" target="_blank">
                                    <font-awesome-icon class="mr-1.5 text-yellow-300" :icon="['fas', 'file-pdf']" />
                                    <span class="text-white hover:text-blue-500">หนังสืออนุมัติจัดประชุม EPIC</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div> -->
            </div>
        <Footer></Footer>
    </div>
</template>

<script>
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import axios from 'axios';

export default {
    name: 'DocumentPage',
    components: {
        Header,
        Footer
    },
    data() {
        return {
            document: {
                d_title: '',
                d_link: '',
                d_type: ''
            }
        }
    },
    mounted() {
        this.onGetData()
    },
    methods: {
        onGetData() {
            axios.get('/api/document')
                .then(res => {
                    let response = res.data.filter(e => e.d_type != 'video')
                    this.document = response
                })
        }
    }
}
</script>

<style>
    
</style>